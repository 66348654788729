import Slider from 'src/components/Slider';
import TradeFormInputLabel from 'src/components/TradeForm/TradeFormInputLabel';
import React, { useEffect } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { JSX } from 'react';

/**
 * 4 Cases for Slider logic - scenario => how to calculate leverage pts available
 *
 * Case 1: SameSide && currentLeverage <= maxLeverage
 * 	=> how many pts to get to max leverage
 *
 * Case 2: NOT SameSide && currentLeverage <= maxLeverage
 * 	=> Current position leverage x2 + remaining to get to maxLeverage
 *
 * Case 3: NOT SameSide && currentLeverage > maxLeverage && otherPositions - currentPosition > maxLeverage
 * 	=> strictly reduce current position size
 *
 * Case 4: NOT SameSide && currentLeverage > maxLeverage && otherPositions - currentPosition < maxLeverage
 * 	=> current position + diff between other positions total and maxLeverage
 *
 * @param param0
 * @returns
 */
const engine = ({
	totalCurrentPosition,
	currentLeverage,
	maxQuote,
	currentPositionQuote,
	maxLeverage,
	currentQuote,
	sameSide,
	setMaxLevSelected,
}: {
	totalCurrentPosition: number;
	currentLeverage: number;
	maxQuote: number;
	currentPositionQuote: number;
	maxLeverage: number;
	currentQuote: number;
	sameSide: boolean;
	setMaxLevSelected: (selection: boolean) => void;
}): {
	currentLeveragePoint: number;
	availableLeveragePoints: number;
	calcNewQuoteFromLeveragePoint: ({ newPt }) => number;
	sliderCase: number;
} => {
	// Calcualte leverage from current position
	const maxPositionAllowed = totalCurrentPosition + maxQuote;

	const currentPositionAbsQuote = Math.abs(currentPositionQuote);
	const maxPositionAbsQuote = Math.abs(maxPositionAllowed);

	const currentPositionPortionOfMaxLeverage = sameSide
		? currentPositionAbsQuote / maxPositionAbsQuote
		: currentPositionAbsQuote / (maxPositionAbsQuote - currentPositionAbsQuote);

	const currentPositionLeverageAmount =
		currentPositionPortionOfMaxLeverage * maxLeverage;

	let availableLeveragePoints = Math.abs((maxLeverage - currentLeverage) * 100);

	let sliderCase = 1;

	if (sameSide) {
		// case 1
		// do nothing
	} else {
		if (currentLeverage < maxLeverage) {
			// case 2
			availableLeveragePoints =
				(currentPositionLeverageAmount * 2 + (maxLeverage - currentLeverage)) *
				100;
			sliderCase = 2;
		} else {
			if (currentLeverage - currentPositionLeverageAmount >= maxLeverage) {
				// case 3
				availableLeveragePoints = currentPositionLeverageAmount * 100;
				sliderCase = 3;
			} else {
				// case 4
				const otherPositionsLeverageAmount =
					currentLeverage - currentPositionLeverageAmount;

				availableLeveragePoints =
					(currentPositionLeverageAmount +
						(maxLeverage - otherPositionsLeverageAmount)) *
					100;
				sliderCase = 4;
			}
		}
	}

	let currentLeveragePoint =
		maxQuote === 0 ? 0 : (currentQuote / maxQuote) * availableLeveragePoints;

	// Restrict slider percentage greater than 0, less than max leverage
	currentLeveragePoint = Math.max(
		Math.min(currentLeveragePoint, availableLeveragePoints),
		0
	);

	setMaxLevSelected(
		// if a user is THIS close to max lev, give them max lev
		currentLeveragePoint >= availableLeveragePoints * 0.995
	);

	const calcNewQuoteFromLeveragePoint = ({ newPt }: { newPt: number }) => {
		const newQuote = (newPt / availableLeveragePoints) * maxQuote;

		return newQuote;
	};

	return {
		currentLeveragePoint,
		availableLeveragePoints,
		calcNewQuoteFromLeveragePoint,
		sliderCase,
	};
};

const SliderButtonComboInput = ({
	currentLeverage,
	maxLeverage,
	targetTradeQuoteSize,
	totalCurrentPositionSize,
	targetMarketCurrentPositionSize: targetCurrentPositionSize,
	maxQuoteSize,
	title,
	titleButtonNode,
	sameSide,
	onCurrentSizeChange,
	leverageAfterTrade,
	setMaxLevSelected,
	largeFont,
	showButtons,
	disabled,
}: {
	currentLeverage: number;
	maxLeverage: number;
	targetTradeQuoteSize: number;
	totalCurrentPositionSize: number;
	maxQuoteSize: number;
	title?: string;
	titleButtonNode?: JSX.Element;
	targetMarketCurrentPositionSize: number;
	sameSide: boolean;
	leverageAfterTrade: number;
	largeFont?: boolean;
	showButtons?: boolean;
	disabled?: boolean;
	onCurrentSizeChange: (quoteSize: number) => void;
	setMaxLevSelected: (selection: boolean) => void;
}): JSX.Element => {
	const isInDepositToTradeFlow = useDriftStore(
		(s) => s.tradeForm.isInDepositToTradeFlow
	);

	const engineValues = engine({
		totalCurrentPosition: totalCurrentPositionSize,
		currentLeverage: currentLeverage,
		maxQuote: maxQuoteSize,
		currentPositionQuote: targetCurrentPositionSize,
		maxLeverage,
		currentQuote: targetTradeQuoteSize,
		sameSide,
		setMaxLevSelected,
	});

	const handleSliderChange = (sliderPct: number) => {
		if (maxQuoteSize === 0) {
			return;
		}

		const amount = engineValues.availableLeveragePoints * (sliderPct / 100);

		onCurrentSizeChange(
			engineValues.calcNewQuoteFromLeveragePoint({
				newPt: amount,
			})
		);
	};

	useEffect(() => {
		// TODO:: This logic shouldn't be required in this component. It should happen somewhere higher up.
		if (isInDepositToTradeFlow) return;

		if (targetTradeQuoteSize > maxQuoteSize) {
			handleSliderChange(100);
		}
	}, [targetTradeQuoteSize, maxQuoteSize, handleSliderChange]);

	const showDefault = targetTradeQuoteSize <= 0;

	// Get slider value from 0 to 100.
	const sliderValue = Math.abs(
		showDefault
			? 0
			: Math.min(
					(engineValues.currentLeveragePoint /
						engineValues.availableLeveragePoints) *
						100,
					100
			  )
	);

	return (
		<div className={'flex flex-col w-full'}>
			{title && (
				<div className="flex justify-between items-center w-full mb-2">
					<TradeFormInputLabel>{title}</TradeFormInputLabel>
					{titleButtonNode}
				</div>
			)}

			<Slider
				onDrop={(newVal) => {
					handleSliderChange(newVal);
				}}
				onMove={(newVal) => {
					handleSliderChange(newVal);
				}}
				step={1}
				value={sliderValue}
				disabled={disabled}
				customButtonOptions={
					showButtons === false
						? []
						: [{ val: 25 }, { val: 50 }, { val: 75 }, { val: 100 }]
				}
				type="leverage"
				displayValue={
					showDefault
						? currentLeverage.toFixed(2)
						: Math.min(maxLeverage, leverageAfterTrade).toFixed(2)
				}
				largeFont={largeFont}
			/>
		</div>
	);
};

export default React.memo(SliderButtonComboInput);
