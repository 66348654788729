'use client';

import { DriftClient, isVariant, MarketType } from '@drift-labs/sdk';
import Env from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftClientIsReady from './useDriftClientIsReady';

const DEFAULT_STATE = {
	maxLeverage: 0,
	highLeverageMaxLeverage: 0,
	isHighLeverage: false,
};

export const getMaxLeverageForMarket = (
	marketType: MarketType,
	marketIndex: number,
	driftClient: DriftClient
) => {
	try {
		if (isVariant(marketType, 'perp')) {
			const marketAccount = driftClient.getPerpMarketAccount(marketIndex);

			const maxLeverage = parseFloat(
				(
					1 /
					((marketAccount?.marginRatioInitial
						? marketAccount?.marginRatioInitial
						: 10000 / Env.defaultMaxMarketLeverage) /
						10000)
				).toFixed(2)
			);

			const marketHasHighLeverageMode =
				!!marketAccount?.highLeverageMarginRatioInitial;

			const highLeverageMaxLeverage = marketHasHighLeverageMode
				? parseFloat(
						(
							1 /
							((marketAccount?.highLeverageMarginRatioInitial
								? marketAccount?.highLeverageMarginRatioInitial
								: 10000 / Env.defaultMaxMarketLeverage) /
								10000)
						).toFixed(2)
				  )
				: 0;

			let user;
			let isHighLeverage = false;
			try {
				user = driftClient.getUser();
				isHighLeverage =
					user?.isHighLeverageMode() && marketHasHighLeverageMode;
			} catch (e) {
				// user doesn't exist, so high leverage mode is false
			}

			return {
				maxLeverage,
				highLeverageMaxLeverage,
				isHighLeverage,
			};
		} else {
			const marketAccount = driftClient.getSpotMarketAccount(marketIndex);

			const liabilityWeight = marketAccount.initialLiabilityWeight / 10000;

			return {
				maxLeverage: parseFloat((1 / (liabilityWeight - 1)).toFixed(2)),
				highLeverageMaxLeverage: 0,
				isHighLeverage: false,
			};
		}
	} catch (e) {
		console.error(e);
		return DEFAULT_STATE;
	}
};

const useMarketMaxLeverage = (marketType: MarketType, marketIndex: number) => {
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const driftClientIsReady = useDriftClientIsReady();

	if (!driftClientIsReady || !driftClient || !driftClient.isSubscribed)
		return DEFAULT_STATE;

	return getMaxLeverageForMarket(marketType, marketIndex, driftClient);
};

export default useMarketMaxLeverage;
