'use client';

import { useEffect, useMemo } from 'react';
import usePrePreparedPerpTradeFormOrder from 'src/hooks/TransactionPrep/usePrePreparedPerpTradeFormOrder';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { PerpMarketAccount } from '@drift-labs/sdk';
import useMemoizedOraclePrice from 'src/hooks/useMemoizedOraclePrice';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import useInfoForCurrentlySelectedMarket from 'src/hooks/useInfoForCurrentlySelectedMarket';
import usePrePreppedTxStore from '../../stores/usePrePreppedTxStore';

const useSyncPrePreparedPerpTradeFormOrder = () => {
	const tradeFormStoreState = useDriftStore((s) => s.tradeForm);
	const selectedMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);
	const oraclePrice = useMemoizedOraclePrice(selectedMarketId);
	const [savedSettings] = useCurrentSettings();
	const selectedMarketInfo = useInfoForCurrentlySelectedMarket();

	const setPreppedTradeFormState = usePrePreppedTxStore((s) => s.set);

	const outputParams = useMemo(
		() => ({
			...tradeFormStoreState,
			targetMarketIndex: selectedMarketId?.marketIndex,
			oraclePrice: oraclePrice?.val,
			currentSettings: savedSettings,
			perpMarketAccount: selectedMarketInfo?.info?.account as PerpMarketAccount,
		}),
		[
			tradeFormStoreState,
			selectedMarketId?.marketIndex,
			oraclePrice?.val,
			savedSettings,
			selectedMarketInfo?.info?.account,
		]
	);

	const prePreparedPerpTradeformTxs =
		usePrePreparedPerpTradeFormOrder(outputParams);

	useEffect(() => {
		setPreppedTradeFormState((s) => {
			s.preppedPerpTradeformTxs = prePreparedPerpTradeformTxs;
		});
	}, [prePreparedPerpTradeformTxs]);
};

export default useSyncPrePreparedPerpTradeFormOrder;
