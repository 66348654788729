import useDriftStore from 'src/stores/DriftStore/useDriftStore';

export const useSetTradeFormPriceBox = () => {
	const setState = useDriftStore((s) => s.set);

	const setTradeFormPriceBox = (price: string) => {
		setState((state) => {
			// if editing limit price, lead side should be base
			state.tradeForm.leadSide = 'base';
			// @ts-expect-error
			state.tradeForm.priceBoxStringValue = price;
		});
	};

	return setTradeFormPriceBox;
};

export const useSetTradeFormBaseSize = () => {
	const setState = useDriftStore((s) => s.set);

	const setTradeFormBaseSize = (baseSize: string) => {
		setState((state) => {
			// @ts-expect-error
			state.tradeForm.baseSizeStringValue = baseSize;
		});
	};

	return setTradeFormBaseSize;
};

export const useSetTradeFormQuoteSize = () => {
	const setState = useDriftStore((s) => s.set);

	const setTradeFormQuoteSize = (quoteSize: string) => {
		setState((state) => {
			// @ts-expect-error
			state.tradeForm.quoteSizeStringValue = quoteSize;
		});
	};

	return setTradeFormQuoteSize;
};

export const useSetTradeFormSecondaryPriceBox = () => {
	const setState = useDriftStore((s) => s.set);

	const setTradeFormSecondaryPriceBox = (price: string) => {
		setState((state) => {
			// @ts-expect-error
			state.tradeForm.secondaryPriceBoxStringValue = price;
		});
	};

	return setTradeFormSecondaryPriceBox;
};
