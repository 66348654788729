'use client';

import { useMemo } from 'react';
import useDriftClientIsReady from './useDriftClientIsReady';
import useDriftClient from './useDriftClient';
import {
	BigNum,
	MarketType,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import NumLib from 'src/utils/NumLib';
import useWalletIsConnected from './useWalletIsConnected';
import UI_UTILS from 'src/utils/uiUtils';
import useAccountExists from './useAccountExists';
import Env from 'src/environmentVariables/EnvironmentVariables';
import { ENUM_UTILS } from '@drift/common';

// const DEFAULT_STATE = {
// 	tradeFee: BigNum.zero(PRICE_PRECISION_EXP),
// 	makerFeePct: 0,
// 	makerFeeBps: -Env.defaultMakerRebateBps,
// 	takerFeePct: 0,
// 	takerFeeBps: Env.defaultTakerFeeBps,
// };

/**
 * Calculates maker or taker fee for a given order quote size in a market
 * TradeFee will return a rebate if post only, fee if not
 * This should be moved to common-ts soon
 *
 * @param quoteSize
 * @param marketType
 * @param selectedMarket
 * @param isPostOnly
 * @returns
 */
export default function useCalculateTradeFee({
	quoteSize,
	marketType,
	marketIndex,
	isPostOnly,
}: {
	quoteSize: number;
	marketType: MarketType;
	marketIndex: number;
	isPostOnly?: boolean;
}) {
	const connected = useWalletIsConnected();
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftClient();
	const userAccountExists = useAccountExists();

	const userAccountClient = useMemo(() => {
		try {
			return (
				connected &&
				driftClientIsReady &&
				driftClient &&
				userAccountExists &&
				driftClient.getUser()
			);
		} catch (e) {
			console.warn(
				`Caught and handling edge case in useCalculateTradeFee. Need to fix this.`
			); // TODO : This is just a bandaid fix for now. Need to properly refactor some lifecycle hooks so that things update in sync and there aren't edge cases like whis where all of the qualifiers can be true but the getUser method still fails (because the state isn't _ACTUALLY_ ready yet)
			return undefined;
		}
	}, [
		connected &&
			driftClientIsReady &&
			driftClient &&
			userAccountExists &&
			driftClient,
	]);

	const {
		makerFeeMultiplier,
		makerFeeBps,
		makerFeePct,
		takerFeeBps,
		takerFeePct,
	} = useMemo(() => {
		let marketFees;
		try {
			marketFees = UI_UTILS.getMarketFees(
				userAccountClient,
				marketType,
				marketIndex,
				driftClient
			);
		} catch (err) {
			// Temp solution for error on delegate account
			marketFees = {
				makerFeeMultiplier: 0,
				makerFeePct: 0,
				makerFeeBps: -Env.defaultMakerRebateBps,
				takerFeeMultiplier: 0,
				takerFeePct: 0,
				takerFeeBps: Env.defaultTakerFeeBps,
			};
		}
		return marketFees;
	}, [userAccountClient, marketType, marketIndex]);

	const tradeFee = useMemo(() => {
		if (!driftClient || !driftClient._isSubscribed) {
			return BigNum.zero(PRICE_PRECISION_EXP);
		}

		if (!userAccountClient) {
			// If no user client, fall back to using driftClient's generic market fees
			const takerFeeMultiplier = driftClient.getMarketFees(
				MarketType.PERP,
				marketIndex
			).takerFee;

			const feeAmountNum = takerFeeMultiplier * quoteSize;

			return BigNum.fromPrint(feeAmountNum.toString(), QUOTE_PRECISION_EXP);
		}

		if (ENUM_UTILS.match(marketType, MarketType.SPOT)) {
			const quoteBigNum = BigNum.fromPrint(`${quoteSize}`, QUOTE_PRECISION_EXP);

			if (isPostOnly) {
				return BigNum.fromPrint(
					`${quoteSize * (Math.abs(makerFeePct) / 100)}`,
					PRICE_PRECISION_EXP
				);
			} else {
				return quoteBigNum.scale(takerFeeBps, 10_000);
			}
		}

		try {
			if (isPostOnly) {
				return BigNum.fromPrint(
					`${quoteSize * (Math.abs(makerFeePct) / 100)}`,
					PRICE_PRECISION_EXP
				);
			} else {
				return BigNum.from(
					userAccountClient.calculateFeeForQuoteAmount(
						NumLib.formatNum.toQuoteBN(quoteSize),
						marketIndex
					),
					QUOTE_PRECISION_EXP
				).shiftTo(PRICE_PRECISION_EXP);
			}
		} catch (err) {
			// Temp solution for error on delegate account
			return BigNum.zero(PRICE_PRECISION_EXP);
		}
	}, [
		driftClient,
		driftClientIsReady,
		userAccountClient,
		isPostOnly,
		quoteSize,
		marketIndex,
		makerFeeMultiplier,
		makerFeePct,
	]);

	return {
		tradeFee,
		makerFeePct,
		makerFeeBps,
		takerFeePct,
		takerFeeBps,
	};
}
