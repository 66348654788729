import {
	BASE_PRECISION_EXP,
	BigNum,
	calculateMarginUSDCRequiredForTrade,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import useAccountData from 'src/hooks/useAccountData';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

/**
 * Determines how much USDC is required for the trade.
 */
export const useCollateralValueRequiredForTrade = () => {
	const baseSizeString = useDriftStore((s) => s.tradeForm.baseSizeStringValue);
	const baseSize = BigNum.fromPrint(baseSizeString, BASE_PRECISION_EXP);
	const currentMarketId = useDriftStore((s) => s.selectedMarket.marketId);
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const userClient = useAccountData()?.client;
	const estEntryPrice = useDriftStore(
		(s) => s.tradeForm.priceImpact.entryPrice
	);
	if (!driftClient?.isSubscribed) {
		return BigNum.from(0, QUOTE_PRECISION_EXP);
	}

	const collateralRequiredBn = calculateMarginUSDCRequiredForTrade(
		driftClient,
		currentMarketId.marketIndex,
		baseSize.val,
		userClient?.getUserAccount().maxMarginRatio,
		undefined,
		estEntryPrice
	);

	return BigNum.from(collateralRequiredBn, QUOTE_PRECISION_EXP);
};
