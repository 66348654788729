'use client';

import {
	ENUM_UTILS,
	MarketId,
	MarketKey,
	UISerializableOrder,
} from '@drift/common';
import { MarketType, OrderType, PositionDirection } from '@drift-labs/sdk';
import { useCallback, useEffect, useState } from 'react';
import {
	BidsAndAsks,
	OrderBookBidAsk,
} from 'src/components/Orderbook/OrderbookTypes';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import { singletonHook } from 'react-singleton-hook';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';

const DEFAULT_USER_BIDS_AND_ASKS: BidsAndAsks = {
	bids: [],
	asks: [],
	bestBid: undefined,
	bestAsk: undefined,
	marketIndex: undefined,
	marketType: MarketType.PERP,
};

type AllMarketsUserBidsAndAsks = Record<MarketKey, BidsAndAsks>;

const DEFAULT_ALL_USER_ORDERS: UISerializableOrder[] = [];

const useCurrentUserBidsAndAsks = (): {
	currentMarketBidsAndAsks: BidsAndAsks;
	allMarketsUserBidsAndAsks: AllMarketsUserBidsAndAsks;
	getUserBidsAndAsksForMarket: (marketKey: MarketKey) => BidsAndAsks;
} => {
	const currentMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);
	const allOrders =
		useDriftAccountStore((s) => s.getCurrentUserAccount()?.openOrders) ??
		DEFAULT_ALL_USER_ORDERS;

	const [currentMarketBidsAndAsks, setCurrentMarketBidsAndAsks] =
		useState<BidsAndAsks>(DEFAULT_USER_BIDS_AND_ASKS);
	const [allMarketsUserBidsAndAsks, setAllMarketsUserBidsAndAsks] = useState<
		Record<MarketKey, BidsAndAsks>
	>({});

	const orderToBidAsk = (
		order: UISerializableOrder
	): OrderBookBidAsk & { marketKey: MarketKey } => {
		return {
			price: order.price.toNum(),
			size: order.baseAssetAmount.sub(order.baseAssetAmountFilled).toNum(),
			type: 'dlob',
			marketKey: new MarketId(order.marketIndex, order.marketType).key,
		};
	};

	useEffect(() => {
		const allMarketsUserBidsAndAsks = allOrdersToUserBidsAndAsks(allOrders);
		setAllMarketsUserBidsAndAsks(allMarketsUserBidsAndAsks);
	}, [allOrders]);

	useEffect(() => {
		if (allMarketsUserBidsAndAsks[currentMarketId.key]) {
			setCurrentMarketBidsAndAsks(
				allMarketsUserBidsAndAsks[currentMarketId.key]
			);
		} else {
			setCurrentMarketBidsAndAsks(DEFAULT_USER_BIDS_AND_ASKS);
		}
	}, [allMarketsUserBidsAndAsks, currentMarketId]);

	function allOrdersToUserBidsAndAsks(
		allOrders: UISerializableOrder[]
	): AllMarketsUserBidsAndAsks {
		const bids = allOrders
			.filter(
				(order) =>
					ENUM_UTILS.match(order.direction, PositionDirection.LONG) &&
					!ENUM_UTILS.match(order.orderType, OrderType.MARKET)
			)
			.map(orderToBidAsk);

		const asks = allOrders
			.filter(
				(order) =>
					ENUM_UTILS.match(order.direction, PositionDirection.SHORT) &&
					!ENUM_UTILS.match(order.orderType, OrderType.MARKET)
			)
			.map(orderToBidAsk);

		const allMarketsWithUserOrders = Array.from(
			new Set(bids.map((b) => b.marketKey).concat(asks.map((a) => a.marketKey)))
		);

		const allMarketsUserBidsAndAsks: AllMarketsUserBidsAndAsks = {};

		allMarketsWithUserOrders.forEach((marketKey) => {
			const bidsForCurrentMarket = bids.filter(
				(b) => b.marketKey === marketKey
			);
			const asksForCurrentMarket = asks.filter(
				(a) => a.marketKey === marketKey
			);
			const bestBid =
				bidsForCurrentMarket.length === 0
					? undefined
					: bidsForCurrentMarket.reduce((previous, current) => {
							if (current.price > previous.price) {
								return current;
							}
							return previous;
					  }, bidsForCurrentMarket[0]);
			const bestAsk =
				asksForCurrentMarket.length === 0
					? undefined
					: asksForCurrentMarket.reduce((previous, current) => {
							if (current.price < previous.price) {
								return current;
							}
							return previous;
					  }, asksForCurrentMarket[0]);

			allMarketsUserBidsAndAsks[marketKey] = {
				bids: bidsForCurrentMarket,
				asks: asksForCurrentMarket,
				bestBid: bestBid?.price,
				bestAsk: bestAsk?.price,
				marketIndex: MarketId.getMarketIdFromKey(marketKey).marketIndex,
				marketType: MarketId.getMarketIdFromKey(marketKey).marketType,
			};
		});

		return allMarketsUserBidsAndAsks;
	}

	const getUserBidsAndAsksForMarket = useCallback(
		(marketKey: MarketKey) => {
			return allMarketsUserBidsAndAsks[marketKey] ?? DEFAULT_USER_BIDS_AND_ASKS;
		},
		[allMarketsUserBidsAndAsks]
	);

	return {
		currentMarketBidsAndAsks,
		allMarketsUserBidsAndAsks,
		getUserBidsAndAsksForMarket,
	};
};

export default singletonHook(
	{
		currentMarketBidsAndAsks: DEFAULT_USER_BIDS_AND_ASKS,
		allMarketsUserBidsAndAsks: {},
		getUserBidsAndAsksForMarket: () => DEFAULT_USER_BIDS_AND_ASKS,
	},
	useCurrentUserBidsAndAsks
);
