import Env from 'src/environmentVariables/EnvironmentVariables';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { ENUM_UTILS } from '@drift/common';
import { MarketType } from '@drift-labs/sdk';
import { DEPOSIT_TO_TRADE_CONFIG } from 'src/constants/featureConfigs/depositToTradeConfig';
import useAccountSpotBalances from 'src/hooks/useAccountBankBalances';

/**
 *
 * @returns Hook which returns if the deposit and trade flow should be enabled based on the current state of the app. At a high level, should be enabled if the wallet isn't connected or if the currently selected wallet doesn't have any deposited spot balances.
 */
const useDepositAndTradeShouldBeEnabled = () => {
	const connected = useWalletIsConnected();
	const selectedMarketType = useDriftStore(
		(s) => s.selectedMarket.current.marketType
	);
	const currentOrderType = useDriftStore((s) => s.tradeForm.orderType);

	const spotBalances = useAccountSpotBalances();

	const hasBalances =
		spotBalances.filter((balance) => balance.balance.gtZero()).length > 0;

	const isInDepositAndTradeState =
		ENUM_UTILS.match(selectedMarketType, MarketType.PERP) &&
		Env.depositAndTradeEnabled &&
		(!connected || !hasBalances) &&
		DEPOSIT_TO_TRADE_CONFIG.enabledOrderTypes.includes(currentOrderType);

	return isInDepositAndTradeState;
};

export default useDepositAndTradeShouldBeEnabled;
