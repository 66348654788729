// TransactionPrep.ts
import { DriftClient } from '@drift-labs/sdk';
import {
	PerpTradeFormOutputProps,
	TRADE_PREP_UTILS,
} from '../../stores/DriftStore/actions/actionHelpers/orderPrep';
import {
	AsyncProcessor,
	AsyncProcessorConfig,
} from '../AsyncProcessor/AsyncProcessor';
import { TransactionInputChangeEvent } from './TransactionPrepConfig';

export type PreppedMarketOrders = Awaited<
	ReturnType<DriftClient['preparePlaceAndTakePerpOrderWithAdditionalOrders']>
>;

export type TransactionPrepCallback = (
	input: PerpTradeFormOutputProps
) => PreppedMarketOrders;

export class TransactionPrepHandler extends AsyncProcessor<
	PerpTradeFormOutputProps,
	PreppedMarketOrders,
	TransactionInputChangeEvent
> {
	constructor(
		config: Omit<
			AsyncProcessorConfig<
				PerpTradeFormOutputProps,
				PreppedMarketOrders,
				TransactionInputChangeEvent
			>,
			'inputValidator'
		>
	) {
		super({
			...config,
			inputValidator: (input: PerpTradeFormOutputProps) => {
				if (!input.currentSettings.placeAndTakeEnabled) {
					return false;
				}

				const parsedValue = TRADE_PREP_UTILS.perp.adjustForStepSize(
					TRADE_PREP_UTILS.perp.formatTradeFormFieldValue.baseSize(
						input.baseSizeStringValue
					),
					input.perpMarketAccount
				);

				if (parsedValue.lteZero()) {
					return false;
				}

				if (input.orderType !== 'market') {
					return false;
				}

				return true;
			},
		});
	}
}
