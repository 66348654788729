import React from 'react';
import { MessageProps } from '../TradeForm/InfoMessage';
import { Info } from '@drift-labs/icons';

type SecondaryInfoMessageProps = Pick<
	MessageProps,
	'message' | 'messageTitle' | 'type' | 'className'
>;

const getColorForMessageType = (type: SecondaryInfoMessageProps['type']) => {
	switch (type) {
		case 'info':
			return 'var(--feedback-button)';
		case 'success':
			return 'var(--positive-green)';
		case 'warn':
			return 'var(--warn-yellow)';
		case 'error':
			return 'var(--negative-red)';
		default: {
			const _exhaustiveCheck: never = type;
			throw new Error('Unhandled type in BlurOverlayInfoMessage');
		}
	}
};

const SecondaryInfoMessage = (props: SecondaryInfoMessageProps) => {
	const styleColour = getColorForMessageType(props.type);

	return (
		<div className="flex flex-col items-center space-y-2">
			<span className="flex flex-col items-center justify-center p-1 rounded-full bg-input-bg">
				<Info color={styleColour} size={32} width={32} />
			</span>
			<div className="flex items-center space-x-2">
				<span className="text-[18px] text-text-emphasis">
					{props.messageTitle}
				</span>
			</div>
			<div className="text-text-default">
				<span>{props.message}</span>
			</div>
		</div>
	);
};

export default SecondaryInfoMessage;
