import React from 'react';
import SecondaryInfoMessage from '../SecondaryInfoMessage';
import { MessageProps } from '../../TradeForm/InfoMessage';

type BlurOverlayMessageProps = Pick<
	MessageProps,
	'message' | 'messageTitle' | 'type' | 'className'
>;

const BgColourEllipsis = () => {
	return (
		<div
			className="absolute left-0 top-0 z-10"
			// style={{
			// borderRadius: '50%',
			// 	background:
			// 		'radial-gradient(50% 50% at 50% 50%, rgba(30, 196, 212, 0.4) 30%, rgba(30, 196, 212, 0) 100%)',
			// 	width: '200%',
			// 	paddingBottom: '200%' /* Adjusts the height to match the width */,
			// 	transform: `translate(-25%, -55%)`,
			// }}
			style={{
				borderRadius: '50%',
				width: '100%',
				paddingBottom: '100%' /* Adjusts the height to match the width */,
				background:
					'radial-gradient(50% 50% at 50% 50%, rgba(30, 196, 212, 0.24) 0%, rgba(30, 196, 212, 0.00) 100%)',
				transform: `translate(0%, -50%)`,
			}}
		/>
	);
};

const BgLinearGradient = () => {
	return (
		<div
			className="absolute w-full h-full left-0 top-0 z-10"
			style={{
				background:
					'linear-gradient(180deg, rgba(16, 114, 114, 0.25) 0%, rgba(32, 91, 109, 0.00) 50%)',
				backdropFilter: 'blur(10px)',
			}}
		/>
	);
};

const BlurOverlayInfoMessage = (props: BlurOverlayMessageProps) => {
	return (
		<div className="relative overflow-hidden">
			<BgColourEllipsis />
			<BgLinearGradient />
			<div className="flex flex-col p-6 space-y-2 relative z-20">
				<SecondaryInfoMessage
					messageTitle={props.messageTitle}
					message={props.message}
					type={props.type}
				/>
			</div>
		</div>
	);
};

export default BlurOverlayInfoMessage;
