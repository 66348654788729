export type TransactionType =
	| 'perp_place_and_take_market_order'
	| 'perp_auction_market_order'
	| 'spot_market_order'
	| 'spot_limit_order';
// ... Could add other types of orders too theoretically. Only implemented a hook for perp_place_and_take_market_order so far.

export type TransactionInputChangeEvent =
	| 'foreground' // We use this in conjunction with expiryEvents in the config below to signal that an update should invalidate the currently prepped transaction
	| 'background';

export type TransactionPrepConfig = {
	prePreppedTxsEnabled: boolean; // Base flag for overall enabling or disabling of pre-prepped transactions
	debounceTimeMs: number; // The window for how frequently the transaction should be pre-prepped. This is essentially a performance guard while we're unsure about the performance implications of prepping transactions. If an "expiry event" occurs, the transaction will be stale until the next debounce tick occurs which should prep a newly acceptable transaction.
	enabledTxTypes: Set<TransactionType>; // The types of transactions that are enabled for pre-prepping
	expiryEvents: Set<TransactionInputChangeEvent>; // The events that can cause a prepped transaction to be stale (will fall back to lazy preparation if the prepped tx is stale)
};

const DEBOUNCE_TIME_MS_ENV = parseInt(
	process.env.NEXT_PUBLIC_PRE_PREPPED_TXS_DEBOUNCE_TIME_MS
);
const FALLBACK_DEBOUNCE_TIME_MS = 1_000;

const ENABLED_TX_TYPES = new Set<TransactionType>([
	'perp_place_and_take_market_order',
]); // Hardcoded for now

export const TRANSACTION_PREP_CONFIG: TransactionPrepConfig = {
	prePreppedTxsEnabled:
		process.env.NEXT_PUBLIC_PRE_PREPPED_TXS_ENABLED === 'true',
	debounceTimeMs: isNaN(DEBOUNCE_TIME_MS_ENV)
		? FALLBACK_DEBOUNCE_TIME_MS
		: DEBOUNCE_TIME_MS_ENV,
	enabledTxTypes: ENABLED_TX_TYPES,
	expiryEvents: new Set<TransactionInputChangeEvent>(['foreground']),
};
