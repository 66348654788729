'use client';

import { useState } from 'react';
import useInterval from '../useInterval';

function useValueWithUpdateInterval<T>(valueGetter: () => T, interval: number) {
	const [returnVal, setReturnVal] = useState(valueGetter());

	useInterval(() => {
		setReturnVal(valueGetter());
	}, interval);

	return [returnVal, setReturnVal] as [T, (val: T) => void];
}

export default useValueWithUpdateInterval;
