import { Copy } from '@drift-labs/icons';
import { twMerge } from 'tailwind-merge';
import { notify } from '../../utils/notifications';
import UI_UTILS from '../../utils/uiUtils';

type CopyButtonProps = {
	copyValue: string;
	toastMessage: string;
	className?: string;
};

const CopyButton = (props: CopyButtonProps) => {
	const handleCopy = () => {
		UI_UTILS.copyToClipboard(props.copyValue);

		notify({
			type: 'info',
			message: props.toastMessage,
		});
	};

	return (
		<Copy
			className={twMerge(
				'hover:cursor-pointer hover:brightness-125 active:opacity-50 text-text-label',
				props.className
			)}
			onClick={handleCopy}
		/>
	);
};

export default CopyButton;
