import {
	ContractTier,
	ContractType,
	MarketType,
	OracleSource,
	PerpMarketAccount,
	SpotMarketAccount,
	UserAccount,
	positionIsAvailable,
} from '@drift-labs/sdk';
import { ENUM_UTILS, MarketId } from '@drift/common';
import { PRE_MARKET_ACKNOWLEDGED_WARNING_KEY } from './PreLaunchMarketConstants';
import InfoMessage from '../TradeForm/InfoMessage';
import { PerpMarketInfo, SpotMarketInfo } from 'src/stores/types';
import UI_UTILS from 'src/utils/uiUtils';

export type PRE_MARKET_ALERT_TO_SHOW =
	| 'acknowledge_terms'
	| 'cant_use_current_account';

export const getIsPreLaunchMarket = (
	marketId: MarketId,
	marketAccount: PerpMarketAccount | SpotMarketAccount
) => {
	//@ts-ignore
	if (!marketId || !marketAccount || !marketAccount.amm || marketId.isSpot) {
		return false;
	}

	const typedMarketAccount = marketAccount as PerpMarketAccount;

	if (
		ENUM_UTILS.match(typedMarketAccount.contractType, ContractType.PREDICTION)
	) {
		return false;
	}

	return ENUM_UTILS.match(
		typedMarketAccount.amm.oracleSource,
		OracleSource.Prelaunch
	);
};

export const getIsIsolatedTierMarket = (
	marketId: MarketId,
	marketAccount: PerpMarketAccount | SpotMarketAccount
) => {
	if (!marketId || !marketAccount || marketId.isSpot) return false;

	//@ts-ignore
	return ENUM_UTILS.match(marketAccount.contractTier, ContractTier.ISOLATED);
};

const userHasNonIsolatedMarketPerpPositions = (
	userAccount: UserAccount,
	getMarketInfo: (marketIndex, marketType) => PerpMarketInfo | SpotMarketInfo
) => {
	if (!userAccount || !userAccount?.perpPositions) {
		return false;
	}

	const nonZeroNonIsolatedMarketPositions = userAccount.perpPositions.filter(
		(position) => {
			const positionIsZero = positionIsAvailable(position);
			if (positionIsZero) return false;

			const isIsolatedMarketPosition = getIsIsolatedTierMarket(
				MarketId.createPerpMarket(position.marketIndex),
				getMarketInfo(position.marketIndex, MarketType.PERP).account
			);

			if (isIsolatedMarketPosition) {
				return false;
			}

			return true;
		}
	);

	return nonZeroNonIsolatedMarketPositions.length > 0;
};

const userHasIsolatedMarketPerpPosition = (
	userAccount: UserAccount,
	getMarketInfo: (marketIndex, marketType) => PerpMarketInfo | SpotMarketInfo
) => {
	if (!userAccount || !userAccount?.perpPositions) {
		return false;
	}

	const nonZeroIsolatedMarketPositions = userAccount.perpPositions.filter(
		(position) => {
			const positionIsZero = positionIsAvailable(position);
			if (positionIsZero) return false;

			return getIsIsolatedTierMarket(
				MarketId.createPerpMarket(position.marketIndex),
				getMarketInfo(position.marketIndex, MarketType.PERP).account
			);
		}
	);

	return nonZeroIsolatedMarketPositions.length > 0;
};

/**
 * Returns whether the input user account should be blocked from trading in a pre-launch market
 * @param userAccount
 * @returns
 */
export const shouldBlockTradingForIsolatedMarket = (
	userAccount: UserAccount,
	getMarketInfo: (marketIndex, marketType) => PerpMarketInfo | SpotMarketInfo
) => {
	if (!userAccount) return false;

	const hasMarginEnabled = userAccount.isMarginTradingEnabled;
	const hasNonUsdcBorrows = UI_UTILS.userHasNonUsdcBorrows(userAccount);
	const hasPerpPositions = userHasNonIsolatedMarketPerpPositions(
		userAccount,
		getMarketInfo
	);

	return hasMarginEnabled || hasNonUsdcBorrows || hasPerpPositions;
};

/**
 * Returns whether the user should be blocked from trading regular markets due to having a pre-launch market position
 * @param userAccount
 * @returns
 */
export const shouldBlockTradingForNonIsolatedMarket = (
	userAccount: UserAccount,
	getMarketInfo: (marketIndex, marketType) => PerpMarketInfo | SpotMarketInfo
) => {
	if (!userAccount) return false;

	return userHasIsolatedMarketPerpPosition(userAccount, getMarketInfo);
};

export const getShouldBlockBorrows = (
	userAccount: UserAccount,
	getMarketInfo: (marketIndex, marketType) => PerpMarketInfo | SpotMarketInfo
) => {
	return userHasIsolatedMarketPerpPosition(userAccount, getMarketInfo);
};

export const checkIfPreLaunchMarketWarningRequired =
	(): PRE_MARKET_ALERT_TO_SHOW => {
		const preLaunchMarketWarningAcknowledged = localStorage.getItem(
			PRE_MARKET_ACKNOWLEDGED_WARNING_KEY
		);

		if (!preLaunchMarketWarningAcknowledged) {
			return 'acknowledge_terms';
		}
		return null;
	};

export const BorrowsDisabledMessage = () => {
	return (
		<InfoMessage
			type="error"
			messageTitle={`Non-USDC Borrows are disabled for this subaccount`}
			message={`As a protocol safety measure, non-USDC borrows are disabled when you have an open position in a Pre-Launch Market. To open a new borrow, use a separate subaccount or close your open perpetuals position.`}
		/>
	);
};
