'use client';

import {
	BASE_PRECISION_EXP,
	BN,
	BigNum,
	L2Level,
	L2OrderBook,
	MarketType,
	PRICE_PRECISION_EXP,
	SpotMarketConfig,
	ZERO,
	groupL2,
	uncrossL2,
} from '@drift-labs/sdk';
import { ENUM_UTILS, MarketId } from '@drift/common';
import { useMemo } from 'react';
import {
	BidsAndAsks,
	LiquidityType,
	OrderBookDisplayState,
	OrderBookDisplayStateBidAsk,
} from 'src/components/Orderbook/OrderbookTypes';
import { formatL2ToDisplay } from '../components/Orderbook/OrderbookUtils';
import { dlog } from '../dev';
import {
	ORDERBOOK_DISPLAY_DEPTH,
	UNCROSS_ONLY_FOR_UI,
} from '../providers/orderbookDisplayProvider';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useMarketStateStore from '../stores/useMarketStateStore';
import useCurrentUserBidsAndAsks from './useCurrentUserBidsAndAsks';
import useMarketInfoDisplayData from './useMarketInfoDisplayData';
import useOrderbookGroupingSizeSelection from './useOrderbookGroupingSizeSelection';
import useGetTickSizeForMarket from './useGetTickSizeForMarket';
import useDevStore from '../stores/useDevStore';
import { MAX_PREDICTION_PRICE_NUM } from 'src/constants/math';

const filterOutDisabledLiquidityTypes = (
	l2Levels: L2Level[],
	disabledLiquidityTypes: LiquidityType[]
) => {
	return l2Levels
		.map((level) => {
			let totalDisabledSize = ZERO;
			const disabledSourceObj = {};

			for (const liquidityType of disabledLiquidityTypes) {
				const disabledLiqSize = level.sources?.[liquidityType];
				if (disabledLiqSize?.gt(ZERO)) {
					totalDisabledSize = totalDisabledSize.add(disabledLiqSize);
					disabledSourceObj[liquidityType] = ZERO;
				}
			}

			return totalDisabledSize.gt(ZERO)
				? {
						...level,
						size: level.size.sub(totalDisabledSize),
						sources: {
							...level.sources,
							...disabledSourceObj,
						},
				  }
				: level;
		})
		.filter((level) => level.size.gt(ZERO));
};

export const l2ToDisplayBidsAndAsks = (
	l2Orderbook: L2OrderBook,
	oraclePrice: BN,
	oracleTwap: BN,
	markTwap: BN,
	tickSizeForMarket: BN,
	groupSizeTickMultiplier: number,
	userBidsAndAsks: BidsAndAsks,
	basePrecisionForMarket: BN,
	disabledLiquidityTypes: LiquidityType[],
	invertOrderbookForPredictionMarket?: boolean // when shorting/selling, it flips bids and asks, and prices are inverted from $1
): OrderBookDisplayState => {
	const filteredBids =
		disabledLiquidityTypes.length > 0
			? filterOutDisabledLiquidityTypes(
					l2Orderbook.bids,
					disabledLiquidityTypes
			  )
			: l2Orderbook.bids;

	const filteredAsks =
		disabledLiquidityTypes.length > 0
			? filterOutDisabledLiquidityTypes(
					l2Orderbook.asks,
					disabledLiquidityTypes
			  )
			: l2Orderbook.asks;

	// # Uncross the liquidity
	//// if UNCROSS_ONLY_FOR_UI is false, then we've already uncrossed the book before placing it in the store
	const uncrossed = UNCROSS_ONLY_FOR_UI
		? uncrossL2(
				filteredBids,
				filteredAsks,
				oraclePrice,
				oracleTwap,
				markTwap,
				tickSizeForMarket,
				new Set(
					userBidsAndAsks.bids.map((b) =>
						BigNum.fromPrint(b.price.toString(), PRICE_PRECISION_EXP).toString()
					)
				),
				new Set(
					userBidsAndAsks.asks.map((a) =>
						BigNum.fromPrint(a.price.toString(), PRICE_PRECISION_EXP).toString()
					)
				)
		  )
		: {
				...l2Orderbook,
				bids: filteredBids,
				asks: filteredAsks,
		  };

	const grouped = groupL2(
		uncrossed,
		tickSizeForMarket.muln(groupSizeTickMultiplier ?? 1), // one user had a weird error where .muln was throwing assertion failed error
		ORDERBOOK_DISPLAY_DEPTH
	);

	// # Convert to UI format
	const formatted = formatL2ToDisplay(grouped, basePrecisionForMarket);

	// Check that bids are in descending order
	if (
		!formatted.bids.every((bid, index) => {
			if (index === 0) return true;

			return bid.price <= formatted.bids[index - 1].price;
		})
	) {
		dlog('orderbook', 'Bids are not in descending order', {
			l2Orderbook,
			oraclePrice,
			oracleTwap,
			markTwap,
			tickSizeForMarket,
			userBidsAndAsks,
			basePrecisionForMarket,
			uncrossed,
			formatted,
		});
	}

	// Check that asks are in ascending order
	if (
		!formatted.asks.every((ask, index) => {
			if (index === 0) return true;

			return ask.price >= formatted.asks[index - 1].price;
		})
	) {
		dlog('orderbook', 'Asks are not in ascending order', {
			l2Orderbook,
			oraclePrice,
			oracleTwap,
			markTwap,
			tickSizeForMarket,
			userBidsAndAsks,
			basePrecisionForMarket,
			uncrossed,
			formatted,
		});
	}

	if (invertOrderbookForPredictionMarket) {
		// Check that bids are in descending order
		const invertPrice = (state: OrderBookDisplayStateBidAsk) => ({
			...state,
			price: MAX_PREDICTION_PRICE_NUM - state.price,
		});

		const flippedAndInvertBidsAndAsk = {
			...formatted,
			bids: formatted.asks.map(invertPrice),
			asks: formatted.bids.map(invertPrice),
		};

		return flippedAndInvertBidsAndAsk;
	} else {
		// Check that asks are in ascending order
		return formatted;
	}
};

const useOrderbookDisplayState = (): {
	marketId: MarketId;
} & OrderBookDisplayState => {
	const uiMarket = useDriftStore((s) => s.selectedMarket.current);
	const invertOrderbookForPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket()
	);
	const marketId = uiMarket.marketId;

	const marketDataState = useMarketStateStore((s) =>
		s.getMarketDataForMarket(marketId)
	);

	const l2liquidityForSelectedMarket = marketDataState?.orderbook;

	const selectedMarketIsPerp = ENUM_UTILS.match(
		uiMarket.marketType,
		MarketType.PERP
	);

	const basePrecisionToUse = selectedMarketIsPerp
		? BASE_PRECISION_EXP
		: (uiMarket.market as SpotMarketConfig).precisionExp;

	const groupingSizeSelection = useOrderbookGroupingSizeSelection();
	const getTickSizeForMarket = useGetTickSizeForMarket();
	const { currentMarketBidsAndAsks } = useCurrentUserBidsAndAsks();
	const marketData = useMarketInfoDisplayData();

	const devDlobSettings = useDevStore((s) => s.dlobSettings);

	const disabledLiquidityTypes = useMemo(() => {
		const disabledLiqTypes: LiquidityType[] = [];

		if (devDlobSettings.showDlob === false) {
			disabledLiqTypes.push('dlob');
		}
		if (devDlobSettings.showPhoenix === false) {
			disabledLiqTypes.push('phoenix');
		}
		if (devDlobSettings.showSerum === false) {
			disabledLiqTypes.push('serum');
		}
		if (devDlobSettings.showVamm === false) {
			disabledLiqTypes.push('vamm');
		}

		return disabledLiqTypes;
	}, [devDlobSettings]);

	const displayState = useMemo(() => {
		if (!l2liquidityForSelectedMarket)
			return {
				bids: [],
				asks: [],
				bestBid: 0,
				bestAsk: 0,
				bidTotalSize: 0,
				askTotalSize: 0,
				marketId: marketId,
			};

		const formattedOrderbook = l2ToDisplayBidsAndAsks(
			l2liquidityForSelectedMarket,
			marketDataState.oracle.price.val,
			BigNum.fromPrint(marketData.oracleTwapLive, PRICE_PRECISION_EXP).val,
			BigNum.fromPrint(marketData.markTwapLive, PRICE_PRECISION_EXP).val,
			getTickSizeForMarket(marketId),
			groupingSizeSelection.options[groupingSizeSelection.selectionIndex],
			currentMarketBidsAndAsks,
			basePrecisionToUse,
			disabledLiquidityTypes,
			invertOrderbookForPredictionMarket
		);

		return {
			...formattedOrderbook,
			marketId: uiMarket.marketId,
		};
	}, [
		uiMarket,
		l2liquidityForSelectedMarket,
		basePrecisionToUse,
		getTickSizeForMarket,
		groupingSizeSelection,
		invertOrderbookForPredictionMarket,
	]);

	return displayState;
};

export default useOrderbookDisplayState;
