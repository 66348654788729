'use client';

import React from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { twMerge } from 'tailwind-merge';

const BuySellToggle = (props: {
	onChange: (newSide: 'buy' | 'sell') => void;
	isPerp: boolean;
}) => {
	const isOnBuySide = useDriftStore((s) => s.tradeForm.side === 'buy');

	const handleChange = (newSide: 'buy' | 'sell') => {
		props.onChange(newSide);
	};

	return (
		<div className="h-[40px] w-full inline-flex">
			<div
				className={twMerge(
					'flex items-center justify-center flex-1 h-full cursor-pointer border border-container-border rounded-l-sm',
					isOnBuySide
						? 'bg-positive-green hover:bg-positive-green-hover border-positive-green'
						: 'text-positive-green'
				)}
				onClick={() => handleChange('buy')}
			>
				<span>{props.isPerp ? 'Long' : 'Buy'}</span>
			</div>
			<div
				className={twMerge(
					'flex items-center justify-center flex-1 h-full cursor-pointer border border-container-border rounded-r-sm',
					!isOnBuySide
						? 'bg-negative-red hover:bg-negative-red-hover border-negative-red'
						: 'text-negative-red'
				)}
				onClick={() => handleChange('sell')}
			>
				<span>{props.isPerp ? 'Short' : 'Sell'}</span>
			</div>
		</div>
	);
};

export default React.memo(BuySellToggle);
