'use client';

import Tippy, { TippyProps } from '@tippyjs/react';
import { PropsWithChildren, ReactNode, useRef } from 'react';

type OnboardingTooltipProps = {
	content: ReactNode;
	placement?: TippyProps['placement'];
	disabled?: boolean;
	visible?: boolean;
	maxWidth?: string;
	zIndex?: number;
};

/**
 * -- NOTE:
 *
 * The Tippy component gets its styling from the tippy.js/dist/tippy.css imported in _app.tsx.
 *
 * Using tailwind, no way to force our styles to override some of the default styles (unless making the tailwind styles have "!important" which isn't ideal)
 *
 * Therefore, we are using Tooltip.css (located in the components folder), which is imported into _app.tsx (a next.js requirement) to override some of the
 * default styles
 */

const OnboardingTooltip = ({
	content,
	placement = 'bottom',
	disabled,
	visible,
	maxWidth = '20rem',
	zIndex,
}: PropsWithChildren<OnboardingTooltipProps>) => {
	const tippyRef = useRef<HTMLElement>(null);

	return (
		<Tippy
			animation="scale"
			appendTo={() => document.body}
			maxWidth={maxWidth}
			interactive
			className={`p-0`}
			disabled={disabled}
			visible={visible}
			content={
				<div className={`flex`}>
					<div className="flex flex-col">
						<div className="flex flex-row justify-center">
							<div className="absolute z-10">
								<div className="rainbow-gradient-arrow-up line"></div>
								<div className="rainbow-gradient-arrow-up bg-container-bg"></div>
							</div>
						</div>
						<div
							className={`p-3 overflow-hidden text-xs leading-5 rounded-sm shadow-md outline-none text-text-default-2 focus:outline-none rainbow-gradient-border bg-container-bg`}
						>
							{content}
						</div>
						<div
							className={`w-full ${placement === 'top' ? 'py-1' : 'py-4'}`}
						></div>
					</div>
				</div>
			}
			arrow={false}
			placement={placement}
			ref={tippyRef}
			zIndex={zIndex}
		>
			<div></div>
		</Tippy>
	);
};

export default OnboardingTooltip;
