'use client';

import {
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	calculateLongShortFundingRateAndLiveTwaps,
	calculateTerminalPrice,
	convertToNumber,
	FUNDING_RATE_BUFFER_PRECISION,
	FUNDING_RATE_PRECISION_EXP,
	MarketStatus,
	OracleSource,
	PEG_PRECISION,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import { ENUM_UTILS } from '@drift/common';
import { useEffect, useRef } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { useImmer } from 'use-immer';
import Env from '../environmentVariables/EnvironmentVariables';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useMarketStateStore from '../stores/useMarketStateStore';
import useCurrentPerpMarketAccount from './useCurrentPerpMarketAccount';
import useDriftClientIsReady from './useDriftClientIsReady';
import useInfoForCurrentlySelectedMarket from './useInfoForCurrentlySelectedMarket';
import UI_UTILS from 'src/utils/uiUtils';

export type MarketData = {
	baseAssetSymbol: string;
	symbol: string;
	indexPrice: number;
	indexErr: string;
	indexTwap: string;
	indexTwapErr: string;
	twapSpreadPct: string;

	totalFee: string;
	totalFeeMinusDistributions: string;
	cumLongRepegProfit: string;
	cumShortRepegProfit: string;
	cumLongFundingRate: string;
	cumShortFundingRate: string;

	baseAssetAmountLong: string;
	baseAssetAmountShort: string;

	fundingRate: string;
	longFundingRate: string;
	shortFundingRate: string;

	lastFundingRate: string;
	estimatedFundingRateCountdownTs: number;
	lastFundingRateTime: string;

	baseVolume: string;
	quoteVolume: string;
	twap: string;
	maxOpenInterest: string;
	openInterest: string;
	longOpenInterest: string;
	shortOpenInterest: string;
	pegMultiplier: string;
	periodicity: string;

	baseAssetAmount: string;
	quoteAssetAmount: string;
	unPeggedQuoteAssetAmount: string;
	bias: string;
	cumFundingRate: string;

	feePoolSize: string;
	terminalPrice: string;
	oracleTwap: string;

	markTwapLive: string;
	oracleTwapLive: string;
	oracleSource: OracleSource;
	lastOracleSlotDelay: number;
	currentSlot: number;

	hasLoadedInitialData: boolean;
};

const BASE_STATE: MarketData = {
	baseAssetSymbol: '',
	symbol: '',
	indexPrice: undefined,
	fundingRate: `0.00`,
	longFundingRate: `0.00`,
	shortFundingRate: `0.00`,
	estimatedFundingRateCountdownTs: 0,
	lastFundingRateTime: `0`,
	baseVolume: `0`,
	lastFundingRate: `0`,
	quoteVolume: `0`,
	twap: `0`,
	maxOpenInterest: '0',
	openInterest: `0`,
	longOpenInterest: `0`,
	shortOpenInterest: `0`,
	pegMultiplier: `0`,
	periodicity: `0`,
	baseAssetAmount: `0`,
	quoteAssetAmount: `0`,
	unPeggedQuoteAssetAmount: `0`,
	baseAssetAmountLong: `0`,
	baseAssetAmountShort: `0`,
	totalFee: `0`,
	totalFeeMinusDistributions: `0`,
	bias: `0`,
	cumFundingRate: `0`,
	indexTwap: `0`,
	indexErr: `0`,
	indexTwapErr: `0`,
	feePoolSize: `0`,
	twapSpreadPct: `0`,
	cumLongRepegProfit: `0`,
	cumShortRepegProfit: `0`,
	cumLongFundingRate: `0`,
	cumShortFundingRate: `0`,
	oracleTwap: `0`,
	markTwapLive: `0`,
	oracleTwapLive: `0`,
	terminalPrice: `0`,
	oracleSource: OracleSource.PYTH,
	lastOracleSlotDelay: 0,
	currentSlot: 0,
	hasLoadedInitialData: false,
};

const LATE_FUNDING_CUTOFF_PERIOD = 60 * 20;

const calculateNextFundingPaymentTs = (
	now: number,
	periodicityNum: number,
	lastFundingTsNum: number
) => {
	let nextFundingTs: number;

	// Determine when next funding is expected
	//// https://docs.drift.trade/funding-rates

	const periodStartForTs = (ts: number) => ts - (ts % periodicityNum);

	const nextFundingEpoch = periodStartForTs(now) + periodicityNum;

	const lastFundingWasLate =
		lastFundingTsNum % periodicityNum > LATE_FUNDING_CUTOFF_PERIOD;

	const lastFundingWasPaidDuringCurrentPeriod =
		periodStartForTs(lastFundingTsNum) === periodStartForTs(now);

	// Check if funding is due
	let fundingIsCurrentlyDue = false;

	//// Case 1 : Last funding was not late, but missed the following payment period
	if (!lastFundingWasLate && !lastFundingWasPaidDuringCurrentPeriod) {
		fundingIsCurrentlyDue = true;
	}

	//// Case 2 : Last funding was late, but missed the skipped payment period
	if (lastFundingWasLate && !lastFundingWasPaidDuringCurrentPeriod) {
		const nextFundingShouldBePaidAt =
			periodStartForTs(lastFundingTsNum) + periodicityNum * 2;

		if (nextFundingShouldBePaidAt <= now) {
			fundingIsCurrentlyDue = true;
		}
	}

	// # Set the expected next funding timestamp
	if (lastFundingWasLate && lastFundingWasPaidDuringCurrentPeriod) {
		nextFundingTs = nextFundingEpoch + periodicityNum;
	} else if (fundingIsCurrentlyDue) {
		nextFundingTs = periodStartForTs(now);
	} else {
		nextFundingTs = nextFundingEpoch;
	}

	return nextFundingTs;
};

/**
 * Note : THIS RETURNS MARKET DATA BASED ON THE LIVE DATA BEING FETCHED FROM THE ORACLE. IT SHOULD ONLY BE USED IN UI DISPLAY BUT NOT IN TRANSACTION CREATION, WE USE THE PRICE DATA IN THE DLOB STORE FOR THAT BECAUSE WE ARE SURE THAT THE DATA IS BEING LOADED FROM A CONSISTENT SOURCE
 * @returns
 */
const useMarketInfoDisplayData = () => {
	// # State
	const selectedMarketInfo = useInfoForCurrentlySelectedMarket();
	const selectedMarketId = selectedMarketInfo?.info?.marketId;
	const marketIsPerp = selectedMarketId?.isPerp;
	const [marketData, setMarketData] = useImmer<MarketData>(BASE_STATE);
	const driftClientIsReady = useDriftClientIsReady();
	const bulkAccountLoader = useDriftStore((s) => s.connection.accountLoader);
	const perpMarketAccount = useCurrentPerpMarketAccount();
	const markPrice = useMarketStateStore(
		(s) =>
			s.getMarkPriceForMarket(selectedMarketId) ??
			BigNum.zero(PRICE_PRECISION_EXP)
	);
	const oraclePriceData = useMarketStateStore((s) =>
		s.getOracleDataForMarket(selectedMarketId)
	);
	const rawOraclePriceData = useMarketStateStore((s) =>
		s.getRawOracleDataForMarket(selectedMarketId)
	);

	const lastFundingTsEstimateUpdate = useRef<number>(0);
	const lastFundingTsMarket = useRef<number>(undefined);

	// # Effect Hooks
	useEffect(() => {
		if (!driftClientIsReady) {
			setMarketData(BASE_STATE);
			return;
		}
	}, [driftClientIsReady]);

	// Counter to decrement the funding rate countdown each second
	useEffect(() => {
		if (!marketIsPerp) return;

		const interval = setInterval(() => {
			setMarketData((s) => {
				s.estimatedFundingRateCountdownTs = Math.max(
					s.estimatedFundingRateCountdownTs - 1,
					0
				);
			});
		}, 1000);

		// cleanup counter
		return () => {
			window.clearInterval(interval);
		};
	}, [selectedMarketInfo?.info?.marketId?.key, marketIsPerp]);

	// Effect to keep info in sync with oracle and market
	useEffect(() => {
		if (!selectedMarketInfo) return;

		(async () => {
			const nowBN = new BN((Date.now() / 1000).toFixed(0));
			const now = nowBN.toNumber();
			const nowSlot = bulkAccountLoader?.mostRecentSlot || 0;

			const market = perpMarketAccount;

			let lastFundingTs: BN;
			let lastFundingRate: BN;
			let periodicity: BN;
			let fundingRateStr: string;
			let shortFundingRate: BN;
			let longFundingRate: BN;
			let markTwapLive: BN;
			let oracleTwapLive: BN;
			const lastOracleSlot = rawOraclePriceData?.slot?.toNumber() ?? 0;

			// todo - refactor to pull marketaccount for either perp/spot
			if (!marketIsPerp) {
				setMarketData((s) => {
					s.hasLoadedInitialData =
						marketData?.indexPrice &&
						marketData?.indexPrice !== BASE_STATE.indexPrice;
					s.oracleSource =
						selectedMarketInfo?.info?.config?.oracleSource ??
						BASE_STATE.oracleSource;
					s.lastOracleSlotDelay = nowSlot - lastOracleSlot;
				});
				return;
			}

			if (market && !ENUM_UTILS.match(market?.status, MarketStatus.DELISTED)) {
				lastFundingTs = market.amm.lastFundingRateTs;
				lastFundingRate = market.amm.lastFundingRate;
				periodicity = market.amm.fundingPeriod;

				let nextFundingTs: number;
				let nextFundingTimeRemaining: number;

				if (lastFundingTs && periodicity) {
					const periodicityNum = periodicity.toNumber();
					const lastFundingTsNum = lastFundingTs.toNumber();

					nextFundingTs = calculateNextFundingPaymentTs(
						now,
						periodicityNum,
						lastFundingTsNum
					);

					nextFundingTimeRemaining = nextFundingTs - now;
					nextFundingTimeRemaining = Math.max(nextFundingTimeRemaining, 0);

					const market = perpMarketAccount;

					if (!oraclePriceData || markPrice.eqZero()) return;

					[markTwapLive, oracleTwapLive, longFundingRate, shortFundingRate] =
						await calculateLongShortFundingRateAndLiveTwaps(
							market,
							rawOraclePriceData,
							markPrice.val,
							nowBN
						);

					fundingRateStr = driftClientIsReady
						? BigNum.from(longFundingRate as BN, FUNDING_RATE_PRECISION_EXP)
								.toFixed(5)
								.toString() +
						  '%/' +
						  BigNum.from(shortFundingRate as BN, FUNDING_RATE_PRECISION_EXP)
								.toFixed(5)
								.toString()
						: 'N/A';
				}

				const markTwapWithMantissa = market.amm.lastMarkPriceTwap;

				const twapSpreadPct = markTwapWithMantissa
					.sub(market.amm.lastMarkPriceTwap)
					.mul(PRICE_PRECISION)
					.mul(new BN(100))
					.div(BN.max(new BN(1), market.amm.lastMarkPriceTwap));

				setMarketData((s) => {
					s.baseAssetSymbol =
						selectedMarketInfo?.info?.genericInfo?.baseAssetSymbol;
					s.symbol = selectedMarketInfo?.info?.genericInfo?.symbol;
					s.terminalPrice = convertToNumber(
						calculateTerminalPrice(market)
					).toFixed(3);
					s.maxOpenInterest = BigNum.from(
						market.amm.maxOpenInterest,
						BASE_PRECISION_EXP
					).toMillified();
					s.openInterest = BigNum.from(
						BN.max(
							market.amm.baseAssetAmountLong,
							market.amm.baseAssetAmountShort.abs()
						),
						BASE_PRECISION_EXP
					).toMillified();
					s.longOpenInterest = BigNum.from(
						market.amm.baseAssetAmountLong,
						BASE_PRECISION_EXP
					).toMillified();
					s.shortOpenInterest = BigNum.from(
						market.amm.baseAssetAmountShort.abs(),
						BASE_PRECISION_EXP
					).toMillified();
					s.oracleTwap = BigNum.from(
						market.amm.lastMarkPriceTwap,
						QUOTE_PRECISION_EXP
					).toFixed(3);
					s.markTwapLive = BigNum.from(
						markTwapLive,
						QUOTE_PRECISION_EXP
					).toFixed(3);
					s.oracleTwapLive = BigNum.from(
						oracleTwapLive,
						QUOTE_PRECISION_EXP
					).toFixed(3);

					s.totalFee = BigNum.from(
						market.amm.totalFee,
						QUOTE_PRECISION_EXP
					).prettyPrint();
					s.totalFeeMinusDistributions = BigNum.from(
						market.amm.totalFeeMinusDistributions,
						QUOTE_PRECISION_EXP
					).prettyPrint();
					s.cumLongFundingRate = BigNum.from(
						market.amm.cumulativeFundingRateLong.div(
							FUNDING_RATE_BUFFER_PRECISION
						),
						QUOTE_PRECISION_EXP
					).prettyPrint();
					s.cumShortFundingRate = BigNum.from(
						market.amm.cumulativeFundingRateShort.div(
							FUNDING_RATE_BUFFER_PRECISION
						),
						QUOTE_PRECISION_EXP
					).prettyPrint();
					s.feePoolSize = BigNum.from(
						market.amm.totalFeeMinusDistributions.sub(
							market.amm.totalFee.div(new BN(2))
						),
						QUOTE_PRECISION_EXP
					).prettyPrint();
					s.bias = BigNum.from(
						market.amm.baseAssetAmountWithAmm,
						BASE_PRECISION_EXP
					).toString();
					s.baseAssetAmount = BigNum.from(
						market.amm.baseAssetReserve,
						BASE_PRECISION_EXP
					).print();
					s.quoteAssetAmount = BigNum.from(
						market.amm.quoteAssetReserve,
						BASE_PRECISION_EXP
					).print();

					s.unPeggedQuoteAssetAmount = BigNum.from(
						market.amm.quoteAssetReserve.mul(market.amm.pegMultiplier),
						BASE_PRECISION_EXP
					)
						.div(PEG_PRECISION)
						.toString();

					s.baseAssetAmountLong = BigNum.from(
						market.amm.baseAssetAmountLong,
						BASE_PRECISION_EXP
					).toString();
					s.baseAssetAmountShort = BigNum.from(
						market.amm.baseAssetAmountShort,
						BASE_PRECISION_EXP
					).toString();

					s.fundingRate = fundingRateStr;
					s.longFundingRate =
						UI_UTILS.getFundingRatePct(longFundingRate).printShort();
					s.shortFundingRate =
						UI_UTILS.getFundingRatePct(shortFundingRate).printShort();
					s.lastFundingRate =
						UI_UTILS.getFundingRatePct(lastFundingRate).printShort();

					s.twap = BigNum.from(
						market.amm.lastMarkPriceTwap,
						QUOTE_PRECISION_EXP
					).toFixed(3);
					s.pegMultiplier =
						(
							market.amm.pegMultiplier.toNumber() / PEG_PRECISION.toNumber()
						).toLocaleString(Env.locale) ?? 'N/A';
					s.lastFundingRateTime = lastFundingTs.toString() ?? 'N/A';
					s.periodicity = periodicity.toString() ?? 'N/A';

					s.twapSpreadPct =
						BigNum.from(twapSpreadPct, QUOTE_PRECISION_EXP).toFixed(5) ?? 'N/A';

					s.oracleSource = market.amm.oracleSource;
					if (nowSlot > 0) {
						s.lastOracleSlotDelay = nowSlot - lastOracleSlot;
						s.currentSlot = nowSlot;
					}

					// If we haven't updated updated the funding estimate for 5 minutes, or the new estimate and the currently running estimate are more than 5 seconds out of sync, then update the current running estimate
					if (
						lastFundingTsMarket.current === undefined ||
						lastFundingTsMarket.current !== market.marketIndex ||
						!lastFundingTsEstimateUpdate.current ||
						Date.now() - lastFundingTsEstimateUpdate.current > 1000 * 60 * 5 ||
						Math.abs(
							s.estimatedFundingRateCountdownTs - nextFundingTimeRemaining
						) > 5
					) {
						s.estimatedFundingRateCountdownTs = nextFundingTimeRemaining;
						lastFundingTsEstimateUpdate.current = Date.now();
						lastFundingTsMarket.current = market.marketIndex;
					}

					s.hasLoadedInitialData = true;
				});
			}
		})();
	}, [
		marketIsPerp,
		selectedMarketInfo?.info?.marketId?.key,
		!!perpMarketAccount,
		markPrice.toNum(),
		driftClientIsReady,
		bulkAccountLoader,
		rawOraclePriceData?.slot,
	]);

	// Set the oracle price when pyth updates or selected market updates
	useEffect(() => {
		const decimalsForMarket =
			selectedMarketInfo?.info?.genericInfo?.tickSizeExponent ?? 2;

		const indexPrice = Number(
			oraclePriceData?.price?.toFixed?.(decimalsForMarket) ?? 0
		);

		const indexErr =
			BigNum.from(
				oraclePriceData?.confidence ?? ZERO,
				PRICE_PRECISION_EXP
			)?.toFixed?.(decimalsForMarket) ?? '0';

		const indexTwap = oraclePriceData?.twap;

		const indexTwapErr = oraclePriceData?.twapConfidence;

		setMarketData((s) => {
			if (indexErr) {
				s.indexErr = indexErr;
			}
			if (indexTwap) {
				s.indexTwap = indexTwap.toFixed(3);
			}
			if (indexTwapErr) {
				s.indexTwapErr = indexTwapErr.toNumber().toFixed(3);
			}

			s.indexPrice = indexPrice;
			s.hasLoadedInitialData = true;
		});
	}, [
		selectedMarketInfo?.info?.marketId?.key,
		oraclePriceData?.slot?.toNumber(),
	]);

	return marketData;
};

export default singletonHook(BASE_STATE, useMarketInfoDisplayData);
