'use client';

import React from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { twMerge } from 'tailwind-merge';
import Button from '../Button';

const YesNoToggle = (props: {
	onChange: (newSide: 'buy' | 'sell') => void;
}) => {
	const isOnBuySide = useDriftStore((s) => s.tradeForm.side === 'buy');

	const handleChange = (newSide: 'buy' | 'sell') => {
		props.onChange(newSide);
	};

	return (
		<div className="h-[40px] w-full inline-flex space-x-4">
			<Button.Secondary
				size="LARGE"
				className={twMerge(
					'rounded',
					!isOnBuySide &&
						'hover:text-positive-green text-text-disabled bg-button-disabled transition-all'
				)}
				positiveGreen
				onClick={() => handleChange('buy')}
			>
				Bet Yes
			</Button.Secondary>
			<Button.Secondary
				size="LARGE"
				className={twMerge(
					'rounded',
					isOnBuySide &&
						'hover:text-negative-red text-text-disabled bg-button-disabled transition-all'
				)}
				negativeRed
				onClick={() => handleChange('sell')}
			>
				Bet No
			</Button.Secondary>
		</div>
	);
};

export default React.memo(YesNoToggle);
