'use client';

import { BigNum } from '@drift-labs/sdk';
import { UIMarket } from '@drift/common';
import { useMemo } from 'react';
import useMarketStateStore from '../stores/useMarketStateStore';
import useDriftStore from '../stores/DriftStore/useDriftStore';

const useBestBidAsk = (
	market?: UIMarket
): { bestBid: BigNum; bestAsk: BigNum } => {
	const currentlySelectedMarketConfig = useDriftStore(
		(s) => s.selectedMarket.current
	);

	const marketToUse = market ? market : currentlySelectedMarketConfig;

	const marketId = marketToUse.marketId;

	const marketData = useMarketStateStore((s) =>
		s.getMarketDataForMarket(marketId)
	);

	const result = useMemo(() => {
		if (!marketData?.derivedState) {
			return undefined;
		}
		return {
			bestBid: marketData.derivedState.bestBid,
			bestAsk: marketData.derivedState.bestAsk,
		};
	}, [marketData]);

	return result;
};

export default useBestBidAsk;
