'use client';

import { useMemo } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { useLocalStorageState } from '../utils/localStorageUtils';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

type GroupingSizeSelectionState = {
	options: number[];
	selectionIndex: number;
	updateSelectionIndex: (newVal: number | string) => void;
};

const DEFAULT_GROUPING_SIZE_OPTIONS = [1, 10, 100, 500, 1000];
const DEFAULT_PREDICTION_MARKET_GROUPING_SIZE_OPTIONS = [1, 10, 100];
const DEFAULT_GROUPING_SIZE_INDEX = 0;

const DEFAULT_STATE = {
	options: DEFAULT_GROUPING_SIZE_OPTIONS,
	selectionIndex: DEFAULT_GROUPING_SIZE_INDEX,
	updateSelectionIndex: () => {},
};

const useOrderbookGroupingSizeSelection = (): GroupingSizeSelectionState => {
	const currentMarket = useDriftStore((s) => s.selectedMarket.current);
	const [groupingSizeSelectionIndex, setGroupingSizeSelection] =
		useLocalStorageState(
			`PREFERRED_DLOB_GROUPING`,
			`${DEFAULT_GROUPING_SIZE_INDEX}`
		);

	const isPredictionMarket = !!currentMarket?.isPredictionMarket;

	const result = useMemo(() => {
		const options = isPredictionMarket
			? DEFAULT_PREDICTION_MARKET_GROUPING_SIZE_OPTIONS
			: DEFAULT_GROUPING_SIZE_OPTIONS;
		const selectionIndex =
			!groupingSizeSelectionIndex ||
			isNaN(+groupingSizeSelectionIndex) ||
			!options[groupingSizeSelectionIndex]
				? DEFAULT_GROUPING_SIZE_INDEX
				: +groupingSizeSelectionIndex;

		return {
			options,
			selectionIndex,
			updateSelectionIndex: (newVal: number | string) =>
				setGroupingSizeSelection(`${newVal}`),
		};
	}, [
		groupingSizeSelectionIndex,
		DEFAULT_GROUPING_SIZE_OPTIONS,
		isPredictionMarket,
	]);

	return result;
};

export default singletonHook(DEFAULT_STATE, useOrderbookGroupingSizeSelection);
