import { FC } from 'react';
import { Close } from '@drift-labs/icons';
import PopoverWrapper, { FloatingPopoverProps } from '../PopoverWrapper';

const MAX_AREA_HEIGHT_PX = 80;
const MIN_AREA_HEIGHT_PX = 30;

enum AreaType {
	TakeProfitAndLong,
	TakeProfitAndShort,
	StopLossAndLong,
	StopLossAndShort,
}

type OrderPreviewPopupProps = {
	isLongPosition: boolean;
	takeProfitPct: number | undefined;
	stopLossPct: number | undefined;
	takeProfitPrice: string;
	stopLossPrice: string;
	onClose: () => void;
	isTPInFocus: boolean;
	isSLInFocus: boolean;
	entryPrice: string;
};

const OrderPreviewPopup: FC<OrderPreviewPopupProps & FloatingPopoverProps> = ({
	isLongPosition,
	takeProfitPct,
	stopLossPct,
	takeProfitPrice,
	stopLossPrice,
	onClose,
	isTPInFocus,
	isSLInFocus,
	entryPrice,
	setFloating,
	floatingStyles,
	getFloatingProps,
}) => {
	const { takeProfitHeight, stopLossHeight } = calcAreaHeights();
	const topPct = isLongPosition ? takeProfitPct : stopLossPct;
	const bottomPct = isLongPosition ? stopLossPct : takeProfitPct;
	const topPrice = isLongPosition ? takeProfitPrice : stopLossPrice;
	const bottomPrice = isLongPosition ? stopLossPrice : takeProfitPrice;

	const isTopAreaDimmed =
		(isLongPosition ? isSLInFocus : isTPInFocus) && !topPct;
	const isBottomAreaDimmed =
		(isLongPosition ? isTPInFocus : isSLInFocus) && !bottomPct;

	function calcAreaHeights() {
		let takeProfitHeight = 0,
			stopLossHeight = 0;
		const hasBothOrdersDefined = !!takeProfitPct && !!stopLossPct;

		if (!hasBothOrdersDefined || takeProfitPct === stopLossPct) {
			takeProfitHeight = MAX_AREA_HEIGHT_PX;
			stopLossHeight = MAX_AREA_HEIGHT_PX;
		} else {
			// both are defined and not equal
			const ratio = takeProfitPct / (stopLossPct + takeProfitPct);
			takeProfitHeight = ratio * MAX_AREA_HEIGHT_PX * 2;
			stopLossHeight = (1 - ratio) * MAX_AREA_HEIGHT_PX * 2;
		}

		return {
			takeProfitHeight: Math.round(takeProfitHeight),
			stopLossHeight: Math.round(stopLossHeight),
		};
	}

	return (
		<PopoverWrapper
			ref={setFloating}
			style={floatingStyles}
			{...getFloatingProps()}
		>
			<div className="flex flex-col gap-2 p-3 text-center rounded bg-container-bg">
				<div className="flex justify-between items-center text-[12px]">
					<div className="text-text-label">Order Preview</div>
					<div className="flex items-center gap-2">
						<button
							className="flex items-center text-red-500"
							onClick={onClose}
						>
							<Close color="var(--text-label)" />
						</button>
					</div>
				</div>
				<div className="flex flex-col text-white text-[10px]">
					<PreviewPanelArea
						height={isLongPosition ? takeProfitHeight : stopLossHeight}
						isOrderFilled={!!topPct}
						percentage={topPct}
						price={topPrice}
						areaType={
							isLongPosition
								? AreaType.TakeProfitAndLong
								: AreaType.StopLossAndShort
						}
						dimmed={isTopAreaDimmed}
					/>
					<TextDivider>
						<div>
							{isLongPosition ? 'Long' : 'Short'} Entry: ${entryPrice}
						</div>
						{!!takeProfitPct && !!stopLossPct && (
							<div>
								{`Risk/Reward Ratio ${(takeProfitPct / stopLossPct).toFixed(
									2
								)}`}
							</div>
						)}
					</TextDivider>
					<PreviewPanelArea
						height={isLongPosition ? stopLossHeight : takeProfitHeight}
						isOrderFilled={!!bottomPct}
						percentage={bottomPct}
						price={bottomPrice}
						areaType={
							isLongPosition
								? AreaType.StopLossAndLong
								: AreaType.TakeProfitAndShort
						}
						dimmed={isBottomAreaDimmed}
					/>
				</div>
			</div>
		</PopoverWrapper>
	);
};

function TextDivider({ children }: { children: React.ReactNode }) {
	return (
		<div className="max-h-[2px] w-full bg-text-disabled flex justify-center items-center z-10">
			<div className="bg-container-bg px-1 py-[1px] rounded text-text-default">
				{children}
			</div>
		</div>
	);
}

function PreviewPanelArea(props: {
	height: number;
	isOrderFilled: boolean;
	percentage: number | undefined;
	price: string;
	areaType: AreaType;
	dimmed: boolean;
}) {
	const isTakeProfit = [
		AreaType.TakeProfitAndLong,
		AreaType.TakeProfitAndShort,
	].includes(props.areaType);
	const isTopArea = [
		AreaType.TakeProfitAndLong,
		AreaType.StopLossAndShort,
	].includes(props.areaType);
	const headerText = props.isOrderFilled ? (
		isTakeProfit ? (
			`Take Profit +${props.percentage}% ($${props.price})`
		) : (
			`Stop Loss -${props.percentage}% ($${props.price})`
		)
	) : (
		<>
			Enter a {isTakeProfit ? 'Take Profit' : 'Stop'} Price{' '}
			<span className="underline">{isTopArea ? 'above' : 'below'}</span> entry
			price
		</>
	);
	const backgroundColorClasses = getBackgroundColor();

	function getBackgroundColor() {
		switch (props.areaType) {
			case AreaType.TakeProfitAndLong:
			default:
				return 'bg-gradient-to-b dark:from-green-40/30 dark:to-green-40/10 from-green-50/30 to-green-50/10';
			case AreaType.StopLossAndLong:
				return 'bg-gradient-to-t dark:from-red-30/30 dark:to-red-30/10 from-red-60/30 to-red-60/10';
			case AreaType.TakeProfitAndShort:
				return 'bg-gradient-to-b dark:from-green-40/10 dark:to-green-40/30 from-green-50/10 to-green-50/30';
			case AreaType.StopLossAndShort:
				return 'bg-gradient-to-t dark:from-red-30/10 dark:to-red-30/30 from-red-60/10 to-red-60/30';
		}
	}

	return (
		<div
			className={`flex flex-col w-[260px] ${
				isTopArea ? 'justify-start' : 'justify-end'
			} ${
				props.dimmed ? 'dark:opacity-40 opacity-60' : ''
			} ${backgroundColorClasses}`}
			style={{
				height: MIN_AREA_HEIGHT_PX + props.height,
			}}
		>
			<div
				className={`bg-opacity-10 dark:bg-opacity-10 text-text-default ${
					isTakeProfit
						? 'dark:bg-green-40 bg-green-50'
						: 'dark:bg-red-30 bg-red-60'
				}`}
			>
				{headerText}
			</div>
		</div>
	);
}

export default OrderPreviewPopup;
