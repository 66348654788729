import Switch, { SwitchProps } from 'src/components/Switch';
import Tooltip from 'src/components/Tooltip/Tooltip';
import TradeFormInputLabel from 'src/components/TradeForm/TradeFormInputLabel';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const SwitchInput = (
	props: {
		label: string;
		tooltip?: string;
		labelClassName?: string;
	} & SwitchProps
) => {
	const onChange = (x) => {
		if (props.disabled) return;
		props.onChange(x);
	};

	return (
		<div
			className={twMerge(
				`flex items-center space-x-1 p-2`,
				props.disabled
					? 'hover:cursor-not-allowed brightness-75'
					: 'hover:cursor-pointer',
				props.labelClassName
			)}
			onClick={onChange}
		>
			{props.tooltip ? (
				<Tooltip allowHover content={props.tooltip} className="text-xs">
					<TradeFormInputLabel className="items-center">
						{props.label}
					</TradeFormInputLabel>
				</Tooltip>
			) : (
				<TradeFormInputLabel className="items-center">
					{props.label}
				</TradeFormInputLabel>
			)}

			<Switch {...props} onChange={onChange} />
		</div>
	);
};

export default React.memo(SwitchInput);
