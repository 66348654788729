'use client';

import { MAX_NUMBER_OF_PERP_POSITIONS } from 'src/constants/constants';
import useAccountData from './useAccountData';

const useUserWillExceedMaxPerpsPositions = (
	marketIndexToAddPositionTo: number
) => {
	const currentAccount = useAccountData();

	const isModifyingACurrentPerpPosition = currentAccount?.openPerpPositions
		.map((position) => position.marketIndex)
		.includes(marketIndexToAddPositionTo);

	if (isModifyingACurrentPerpPosition) {
		return false;
	}

	const userExceededMaxPerpsPositions =
		currentAccount?.openPerpPositions.length >= MAX_NUMBER_OF_PERP_POSITIONS;

	return userExceededMaxPerpsPositions;
};

export default useUserWillExceedMaxPerpsPositions;
