import produce from 'immer';
import { create } from 'zustand';
import { Transaction, VersionedTransaction } from '@solana/web3.js';

export interface PrePreppedTxStore {
	set: (x: (s: PrePreppedTxStore) => void) => void;
	get: (x: any) => PrePreppedTxStore;
	preppedPerpTradeformTxs: null | {
		placeAndTakeTx: Transaction | VersionedTransaction;
		cancelExistingOrdersTx: Transaction | VersionedTransaction;
		settlePnlTx: Transaction | VersionedTransaction;
	};
}

const usePrePreppedTxStore = create<PrePreppedTxStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	preppedPerpTradeformTxs: null,
}));

export default usePrePreppedTxStore;
