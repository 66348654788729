import Env from 'src/environmentVariables/EnvironmentVariables';
import Tooltip from '../Tooltip/Tooltip';
import { twMerge } from 'tailwind-merge';
import ValueChangeBlink from '../Utils/ValueChangeBlink';
import { Info } from '@drift-labs/icons';
import UIHelpTextLink from '../UIHelpTextLink';
import { BN, BigNum, PRICE_PRECISION_EXP } from '@drift-labs/sdk';
import NumLib from 'src/utils/NumLib';
import { TippyProps } from '@tippyjs/react';
import EventEmitter from 'events';

type EstEntryPriceProps = {
	estimatedEntryPrice?: BN;
	placement?: TippyProps['placement'];
	showPriceEstimateOracleDivergenceWarning?: boolean;
	childElement?: React.JSX.Element;
	manualEmitter?: EventEmitter;
};

const EstEntryPriceTooltip = (props: EstEntryPriceProps) => {
	return (
		<Tooltip
			allowHover
			showArrow
			placement={props.placement}
			manualEmitter={props.manualEmitter}
			content={
				<>
					<div className="flex flex-col items-start mb-2 space-y-2">
						<span>Estimated entry price is based on the bid/ask spread.</span>
					</div>
					<div className="flex flex-col items-start space-y-2">
						<span>
							Market Orders are automatically routed through Just-In-Time (JIT)
							auctions, allowing Market Makers to give you a better price than
							what&apos;s estimated.
						</span>
						<UIHelpTextLink
							href="https://docs.drift.trade/market-makers/market-maker-participation#just-in-time-liquidity"
							text="Learn More"
						/>
					</div>
					{props.showPriceEstimateOracleDivergenceWarning && (
						<div className="flex flex-col items-start mt-2 space-y-2 text-warn-yellow">
							<span>
								{`Estimated entry price diverges over ${Env.priceDivergenceWarningThreshold}% from oracle, proceed
								with caution.`}
							</span>
						</div>
					)}
				</>
			}
		>
			{props.childElement || (
				<div className="flex justify-between w-full mb-1">
					<span style={{ display: 'inline' }}>
						<div
							className={twMerge(
								'text-text-label',
								props.showPriceEstimateOracleDivergenceWarning &&
									'text-warn-yellow'
							)}
						>
							Est. Entry Price <Info size={14} className="relative top-1" />
						</div>
					</span>

					<div className="flex items-center">
						<ValueChangeBlink value={props.estimatedEntryPrice}>
							<div
								className={twMerge(
									'text-text-emphasis',
									props.showPriceEstimateOracleDivergenceWarning &&
										'text-warn-yellow'
								)}
							>
								{'$'}
								{props.estimatedEntryPrice
									? NumLib.formatNum.toDisplayPrice(
											BigNum.from(
												props.estimatedEntryPrice,
												PRICE_PRECISION_EXP
											).toNum()
									  )
									: 0.0}{' '}
							</div>
						</ValueChangeBlink>
					</div>
				</div>
			)}
		</Tooltip>
	);
};

export default EstEntryPriceTooltip;
