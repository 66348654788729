import Tooltip from './Tooltip';
import { memo } from 'react';

const MAKER_REBATE_MESSAGE = `Post-only limit orders receive a variable rebate based on the market and user fee tier.`;

const MakerRebateTooltip = () => {
	return (
		<Tooltip
			allowHover
			content={
				<>
					<span>{MAKER_REBATE_MESSAGE}</span>
					<a
						className="inline-flex hover:cursor-pointer ml-0.5"
						target="_blank"
						rel="noreferrer"
						href={'https://docs.drift.trade/trading/trading-fees'}
						onClick={() => {
							window.open(
								'https://docs.drift.trade/trading/trading-fees',
								'_blank'
							);
						}}
					>
						Learn more
					</a>
				</>
			}
			className="ml-1"
		/>
	);
};

export default memo(MakerRebateTooltip);
