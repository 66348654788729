import React from 'react';
import { twMerge } from 'tailwind-merge';

type BlurOverlayPropType = {
	showBlur: boolean;
	overlayContent: React.ReactNode;
	zIndex?: number;
};

/**
 * Renders an blur overlay with some overlapping inner content. With CURRENT implementation, probably requires that it is a sibling of the content you want to overlay, with a shared parent that has relatve + w-full + h-full styling.
 */
const BlurOverlay = ({
	showBlur,
	overlayContent,
	zIndex,
}: BlurOverlayPropType) => {
	return (
		showBlur && (
			<div
				className={twMerge(
					zIndex === undefined ? undefined : `z-${zIndex}`,
					'absolute inset-0 bg-opacity-25 backdrop-blur-sm h-full w-full'
				)}
			>
				<div className="flex flex-col gap-4 h-full w-full">
					{overlayContent}
				</div>
			</div>
		)
	);
};

export default BlurOverlay;
