'use client';

import { singletonHook } from 'react-singleton-hook';
import useDriftClientIsReady from '../useDriftClientIsReady';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { DriftClient } from '@drift-labs/sdk';
import { useEffect, useState } from 'react';
import Env, {
	PERP_MARKETS_LOOKUP,
	SPOT_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import { UIMarket } from '@drift/common';
import { PredictionMarketConfig } from 'src/@types/predictionMarkets';

export type MarketBooster = {
	market: UIMarket;
	boosterType: 'taker' | 'maker' | 'oi' | 'deposit' | 'borrow' | 'insurance';
	boosterAmount: number;
	boostedEarnRate: number;
};

const perpMarkets = PERP_MARKETS_LOOKUP.map((market) =>
	UIMarket.createPerpMarket(market.marketIndex)
);

const spotMarkets = SPOT_MARKETS_LOOKUP.map((market) =>
	UIMarket.createSpotMarket(market.marketIndex)
);

const combinedMarkets = [...perpMarkets, ...spotMarkets].sort((a, b) =>
	a.symbol.localeCompare(b.symbol)
);

const _useFuelBoostedMarkets = () => {
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftStore(
		(s) => s.driftClient?.client
	) as DriftClient;

	const [boosters, setBoosters] = useState<MarketBooster[]>([]);
	const [boostersLoaded, setBoostersLoaded] = useState(false);

	useEffect(() => {
		if (driftClient && driftClientIsReady && combinedMarkets.length) {
			const boosters: MarketBooster[] = [];

			const fuelBaseEarnRates = Env.fuelBaseEarnRates;

			combinedMarkets.forEach((market) => {
				const isPerp = market.isPerp;

				if (isPerp) {
					const perpMarketAccount = driftClient.getPerpMarketAccount(
						market.marketIndex
					);

					// skip if prediction market and resolved
					if (
						market.isPredictionMarket &&
						PredictionMarketConfig.isResolved(perpMarketAccount)
					) {
						return;
					}

					if (perpMarketAccount.fuelBoostTaker > 1) {
						boosters.push({
							market,
							boosterType: 'taker',
							boosterAmount: perpMarketAccount.fuelBoostTaker,
							boostedEarnRate:
								(fuelBaseEarnRates.taker || 1) *
								perpMarketAccount.fuelBoostTaker,
						});
					}

					if (perpMarketAccount.fuelBoostMaker > 1) {
						boosters.push({
							market,
							boosterType: 'maker',
							boosterAmount: perpMarketAccount.fuelBoostMaker,
							boostedEarnRate:
								(fuelBaseEarnRates.maker || 1) *
								perpMarketAccount.fuelBoostMaker,
						});
					}

					if (perpMarketAccount.fuelBoostPosition > 1) {
						boosters.push({
							market,
							boosterType: 'oi',
							boosterAmount: perpMarketAccount.fuelBoostPosition,
							boostedEarnRate:
								(fuelBaseEarnRates.oi || 1) *
								perpMarketAccount.fuelBoostPosition,
						});
					}
				} else {
					const spotMarketAccount = driftClient.getSpotMarketAccount(
						market.marketIndex
					);

					if (spotMarketAccount.fuelBoostDeposits > 1) {
						boosters.push({
							market,
							boosterType: 'deposit',
							boosterAmount: spotMarketAccount.fuelBoostDeposits,
							boostedEarnRate:
								(fuelBaseEarnRates.deposit || 1) *
								spotMarketAccount.fuelBoostDeposits,
						});
					}

					if (spotMarketAccount.fuelBoostBorrows > 1) {
						boosters.push({
							market,
							boosterType: 'borrow',
							boosterAmount: spotMarketAccount.fuelBoostBorrows,
							boostedEarnRate:
								(fuelBaseEarnRates.borrow || 1) *
								spotMarketAccount.fuelBoostBorrows,
						});
					}

					if (spotMarketAccount.fuelBoostTaker > 1) {
						boosters.push({
							market,
							boosterType: 'taker',
							boosterAmount: spotMarketAccount.fuelBoostTaker,
							boostedEarnRate:
								(fuelBaseEarnRates.taker || 1) *
								spotMarketAccount.fuelBoostTaker,
						});
					}

					if (spotMarketAccount.fuelBoostMaker > 1) {
						boosters.push({
							market,
							boosterType: 'maker',
							boosterAmount: spotMarketAccount.fuelBoostMaker,
							boostedEarnRate:
								(fuelBaseEarnRates.maker || 1) *
								spotMarketAccount.fuelBoostMaker,
						});
					}

					if (spotMarketAccount.fuelBoostInsurance > 1) {
						boosters.push({
							market,
							boosterType: 'insurance',
							boosterAmount: spotMarketAccount.fuelBoostInsurance,
							boostedEarnRate:
								(fuelBaseEarnRates.staking || 1) *
								spotMarketAccount.fuelBoostInsurance,
						});
					}
				}
			});

			setBoosters(boosters);
			setBoostersLoaded(true);
		}
	}, [driftClient, driftClientIsReady]);

	return { boosters, boostersLoaded };
};

export const useFuelBoostedMarkets = singletonHook(
	{ boosters: [], boostersLoaded: false },
	_useFuelBoostedMarkets
);

export const useFuelBoostsForMarket = (
	market: UIMarket
): { marketBoosters: MarketBooster[]; description: string } => {
	const { boosters } = useFuelBoostedMarkets();

	const marketBoosters = boosters.filter((booster) =>
		booster.market.equals(market)
	);
	const takerBoost = marketBoosters.find((b) => b.boosterType === 'taker');
	const makerBoost = marketBoosters.find((b) => b.boosterType === 'maker');

	const description =
		takerBoost && makerBoost
			? `Earn a boosted rate of ${takerBoost.boosterAmount} FUEL for every $1 in taker volume and ${makerBoost.boosterAmount} FUEL for every $1 in maker volume`
			: takerBoost
			? `Earn a boosted rate of ${takerBoost.boosterAmount} FUEL for every $1 in taker volume`
			: makerBoost
			? `Earn a boosted rate of ${makerBoost.boosterAmount} FUEL for every $1 in maker volume`
			: `No FUEL boost available`;

	return { marketBoosters, description };
};
