'use client';

import {
	BigNum,
	MarketType,
	PERCENTAGE_PRECISION_EXP,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import { COMMON_MATH, MarketId } from '@drift/common';
import React, {
	PropsWithChildren,
	createContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import useMarketStateStore from 'src/stores/useMarketStateStore';
import { useImmer } from 'use-immer';
import { MarketPriceState } from '../@types/types';
import {
	BidsAndAsks,
	GROUPING_TYPE,
	OrderbookDisplayProps,
} from '../components/Orderbook/OrderbookTypes';
import useCurrentUserBidsAndAsks from '../hooks/useCurrentUserBidsAndAsks';
import useInfoForCurrentlySelectedMarket from '../hooks/useInfoForCurrentlySelectedMarket';
import useOrderbookDisplayState from '../hooks/useOrderbookDisplayState';
import useOrderbookGroupingSizeSelection from '../hooks/useOrderbookGroupingSizeSelection';
import useGetTickSizeForMarket from '../hooks/useGetTickSizeForMarket';
import useValueWithUpdateInterval from '../hooks/utils/useValueWithUpdateInterval';
import useDriftStore from '../stores/DriftStore/useDriftStore';

const DEFAULT_GROUPING_TYPE = GROUPING_TYPE.QUOTE;
export const ORDERBOOK_DISPLAY_DEPTH = 25;
export const UNCROSS_ONLY_FOR_UI = false;

const BIDS_ASKS_DEFAULT_STATE: BidsAndAsks = {
	bids: [],
	asks: [],
	bestBid: undefined,
	bestAsk: undefined,
	marketIndex: undefined,
	marketType: MarketType.PERP,
};

const DEFAULT_L2_STATE = {
	bids: [],
	asks: [],
};

const DEFAULT_MARKET_ID = MarketId.createPerpMarket(0);

const DEFAULT_ORDERBOOK_DISPLAY_PROPS: OrderbookDisplayProps = {
	marketId: MarketId.createPerpMarket(0),
	markPrice: undefined,
	orderbookDisplayState: {
		bids: [],
		asks: [],
		bidTotalSize: 0,
		askTotalSize: 0,
	},
	depth: undefined,
	groupingSizeOptions: [100],
	groupingSizeSelection: [0, (_newVal) => {}],
	groupingType: undefined,
	currentUserBidsAndAsks: BIDS_ASKS_DEFAULT_STATE,
	loading: true,
	marketPriceState: undefined,
	latestMarketTrades: [],
	isDisplayingOrderbook: false,
	setIsDisplayingOrderbook: (_newVal: boolean) => {},
};

export const OrderbookDisplayContext = createContext<OrderbookDisplayProps>(
	DEFAULT_ORDERBOOK_DISPLAY_PROPS
);

const useGroupingSizeOptions = (selectedMarketId: MarketId) => {
	const getTickSizeForMarket = useGetTickSizeForMarket();

	const groupingSizeState = useOrderbookGroupingSizeSelection();

	const tickSizeBigNum = useMemo(
		() =>
			BigNum.from(getTickSizeForMarket(selectedMarketId), PRICE_PRECISION_EXP),
		[getTickSizeForMarket, PRICE_PRECISION_EXP, selectedMarketId]
	);

	const groupingSizeOptions = useMemo(
		() =>
			groupingSizeState.options.map((opt) =>
				BigNum.from(opt).mul(tickSizeBigNum).toNum()
			),
		[groupingSizeState, tickSizeBigNum]
	);

	return groupingSizeOptions;
};

const OrderbookDisplayDataProvider = (props: PropsWithChildren<any>) => {
	const [orderbookDisplayContextState, setOrderbookDisplayContextState] =
		useImmer<OrderbookDisplayProps>(DEFAULT_ORDERBOOK_DISPLAY_PROPS);

	const currentMarketId =
		useInfoForCurrentlySelectedMarket()?.info?.marketId ?? DEFAULT_MARKET_ID;

	const l2State = useMarketStateStore((s) => {
		return s?.getDlobStateForMarket?.(currentMarketId) ?? DEFAULT_L2_STATE;
	});

	const oraclePrice = useMarketStateStore((s) => {
		return s?.getOraclePriceForMarket?.(currentMarketId);
	});

	// Mark Price
	const marketDataState = useMarketStateStore((s) =>
		s.getMarketDataForMarket(currentMarketId)
	);
	const markPrice = marketDataState?.derivedState?.markPrice;

	// Orderbook Display State
	const orderbookDisplayState = useOrderbookDisplayState();

	// Depth
	const depth = ORDERBOOK_DISPLAY_DEPTH;

	// Grouping Size Options
	const groupingSizeOptions = useGroupingSizeOptions(currentMarketId);

	// Grouping Size Selection
	const groupingSizeState = useOrderbookGroupingSizeSelection();
	const groupingSizeSelection = useMemo(() => {
		return [
			groupingSizeState.selectionIndex,
			groupingSizeState.updateSelectionIndex,
		];
	}, [
		groupingSizeState.selectionIndex,
		groupingSizeState.updateSelectionIndex,
	]) as [number, (newVal: number | string) => void];

	// Grouping Type
	const groupingType = DEFAULT_GROUPING_TYPE;

	// Current User Bids and Asks
	const { currentMarketBidsAndAsks } = useCurrentUserBidsAndAsks();

	// Market Price State
	const [marketPriceState, setMarketPriceState] = useState<MarketPriceState>();

	const latestMarketTrades = useDriftStore((s) => s.marketTradeHistory.trades);

	useEffect(() => {
		setOrderbookDisplayContextState((s) => {
			s.orderbookDisplayState = orderbookDisplayState;
			s.groupingSizeOptions = groupingSizeOptions;
			s.groupingSizeSelection = groupingSizeSelection;
		});
	}, [orderbookDisplayState, groupingSizeOptions, groupingSizeSelection]);

	useEffect(() => {
		setOrderbookDisplayContextState((s) => {
			s.setIsDisplayingOrderbook = (newVal: boolean) => {
				setOrderbookDisplayContextState((s) => {
					s.isDisplayingOrderbook = newVal;
				});
			};
		});
	}, []);

	const getValuesToRender = (): Omit<
		OrderbookDisplayProps,
		| 'orderbookDisplayState'
		| 'groupingSizeOptions'
		| 'groupingSizeSelection'
		| 'isDisplayingOrderbook'
		| 'setIsDisplayingOrderbook'
	> => ({
		marketId: currentMarketId,
		markPrice: markPrice?.toNum(),
		depth,
		groupingType,
		currentUserBidsAndAsks: currentMarketBidsAndAsks,
		loading:
			!currentMarketId ||
			!currentMarketId.equals(orderbookDisplayState.marketId),
		marketPriceState,
		latestMarketTrades,
	});

	// Only update the values to render once per second
	const [valuesToRender, _manualValueUpdate] = useValueWithUpdateInterval(
		getValuesToRender,
		1000
	);

	useEffect(() => {
		setOrderbookDisplayContextState((s) => {
			s.marketId = valuesToRender.marketId;
			s.markPrice = valuesToRender.markPrice;
			s.depth = valuesToRender.depth;
			s.groupingType = valuesToRender.groupingType;
			s.currentUserBidsAndAsks = valuesToRender.currentUserBidsAndAsks;
			s.loading = valuesToRender.loading;
			s.marketPriceState = valuesToRender.marketPriceState;
			s.latestMarketTrades = valuesToRender.latestMarketTrades;
		});
	}, [valuesToRender]);

	useEffect(() => {
		const newMarketPriceState = COMMON_MATH.calculateSpreadBidAskMark(
			l2State,
			oraclePrice?.val
		);

		setMarketPriceState({
			bestBid: BigNum.from(
				newMarketPriceState.bestBidPrice,
				PRICE_PRECISION_EXP
			),
			bestAsk: BigNum.from(
				newMarketPriceState.bestAskPrice,
				PRICE_PRECISION_EXP
			),
			markPrice: BigNum.from(
				newMarketPriceState.markPrice,
				PRICE_PRECISION_EXP
			),
			spreadPct: BigNum.from(
				newMarketPriceState.spreadPct,
				PERCENTAGE_PRECISION_EXP
			),
			spreadQuote: BigNum.from(
				newMarketPriceState.spreadQuote,
				QUOTE_PRECISION_EXP
			),
		});
	}, [l2State]);

	return (
		<OrderbookDisplayContext value={orderbookDisplayContextState}>
			{props.children}
		</OrderbookDisplayContext>
	);
};

export default React.memo(OrderbookDisplayDataProvider);
