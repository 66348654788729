'use client';

import {
	AMM_RESERVE_PRECISION_EXP,
	BASE_PRECISION,
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	ContractType,
	FOUR,
	L2OrderBook,
	MarketStatus,
	MarketType,
	PerpMarketAccount,
	PositionDirection,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	SpotMarketAccount,
	SpotMarketConfig,
	User,
	ZERO,
} from '@drift-labs/sdk';
import {
	ENUM_UTILS,
	matchEnum,
	MultiSwitch,
	UI_ORDER_TYPES,
	UIOrderType,
} from '@drift/common';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Env, {
	marketStepSizes,
} from 'src/environmentVariables/EnvironmentVariables';
import useAccountData from 'src/hooks/useAccountData';
import useAccountExists from 'src/hooks/useAccountExists';
import useAccountTargetSpotBalance from 'src/hooks/useAccountTargetSpotBalance';
import useCurrentMarketMarkPrice from 'src/hooks/useCurrentMarketMarkPrice';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useMarkPrice from 'src/hooks/useMarkPrice';
import { useTradeformPriceImpact } from 'src/hooks/usePriceImpact';
import useUserAccountIsReady from 'src/hooks/useUserAccountIsReady';
import useDriftStore, { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import NumLib from 'src/utils/NumLib';
import UI_UTILS from 'src/utils/uiUtils';
import useCurrentPerpMarketAccount from '../../hooks/useCurrentPerpMarketAccount';
import useWalletIsConnected from '../../hooks/useWalletIsConnected';
import Button from '../Button';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import {
	MAX_NUMBER_OF_PERP_POSITIONS,
	MAX_PREDICTION_PRICE_BIGNUM,
	MAX_PREDICTION_PRICE_NUM,
	SUPER_STAKE_ACCOUNT_NAMES,
} from 'src/constants/constants';
import useUserWillExceedMaxPerpsPositions from 'src/hooks/useUserWillExceedMaxPerpsPositions';
import useSafePush from 'src/hooks/useSafePush';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import useMemoizedOraclePrice from 'src/hooks/useMemoizedOraclePrice';
import {
	MaxFundsAvailableBreakdown,
	PerpTradeFormState,
	TradeFormMessageType,
	TradeFormState,
} from 'src/@types/types';
import {
	getMessageInfoForOrderViolation,
	getPriceBoxHeading,
	getShouldShowSecondaryPriceBox,
	MessageInfo,
} from 'src/utils/trade';
import useIsLiteMode from 'src/hooks/useIsLiteMode';
import useL2StateForMarket from 'src/hooks/useL2StateForMarket';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import use24hMarketDataFromStore from 'src/hooks/use24hMarketDataFromStore';
import { useExceedsMaxSpotBalance } from 'src/hooks/useMaxSpotBalance';
import { MAX_SPOT_POSITIONS_ERROR_MESSAGE } from 'src/constants/markets';
import { useDebounce } from 'react-use';
import {
	useSetTradeFormBaseSize,
	useSetTradeFormPriceBox,
	useSetTradeFormQuoteSize,
	useSetTradeFormSecondaryPriceBox,
} from 'src/components/TradeForm/useSetTradeFormInputState';
import useMarketStateStore from 'src/stores/useMarketStateStore';
import useDepositAndTradeShouldBeEnabled from 'src/components/TradeForm/useDepositAndTradeShouldBeEnabled';

const handleTotalMaxTradeSizeCalculation = (
	finalSidePrice: BigNum,
	currentOpenPositionSidePrice: BigNum,
	maxTradeSizeUsdc: ReturnType<User['getMaxTradeSizeUSDCForPerp']>
): MaxFundsAvailableBreakdown => {
	// User.getMaxTradeSizeUSDCForPerp() returns the max trade quote size for each side of the position (if flipping position) - final side and current side

	/**
	 * We then use the correct price to derive the max trade base size for each side
	 *
	 * finalSideTradeSize = The size available once the user has closed their current position.
	 * currentSideTradeSize = The size required for the user to close their current position. This will be 0 if the user is not flipping their position.
	 * */

	const {
		tradeSize: finalSideTradeSize,
		oppositeSideTradeSize: currentSideTradeSize,
	} = maxTradeSizeUsdc;

	const finalSideMaxQuoteNum = BigNum.from(
		finalSideTradeSize,
		QUOTE_PRECISION_EXP
	).toNum();

	const currentSideMaxQuoteNum = BigNum.from(
		currentSideTradeSize,
		QUOTE_PRECISION_EXP
	).toNum();

	const finalSideMaxBase = !finalSidePrice.eqZero()
		? BigNum.from(finalSideTradeSize, QUOTE_PRECISION_EXP)
				.scale(PRICE_PRECISION, finalSidePrice.val)
				.toNum()
		: 0;

	const currentOpenPositionSideMaxBase = !currentOpenPositionSidePrice.eqZero()
		? BigNum.from(currentSideTradeSize, QUOTE_PRECISION_EXP)
				.scale(PRICE_PRECISION, currentOpenPositionSidePrice.val)
				.toNum()
		: 0;

	return {
		newSide: {
			quoteAmount: finalSideMaxQuoteNum,
			baseAmount: finalSideMaxBase,
		},
		currentSide: {
			quoteAmount: currentSideMaxQuoteNum,
			baseAmount: currentOpenPositionSideMaxBase,
		},
	};
};

const DepositButton = () => {
	const actions = useDriftActions();
	const wallet = useDriftStore((s) => s.wallet);
	const safePush = useSafePush();

	const clickAction = useCallback(() => {
		if (
			(wallet?.isMagicAuth || wallet?.isMetamask) &&
			wallet?.currentSolBalance.eqZero()
		) {
			safePush('/onboarding/fund-your-account?source=tradeform');
		} else {
			actions.showDepositModal(0, undefined, true);
		}
	}, [actions, wallet]);

	return (
		<Button.Secondary size="MEDIUM" onClick={clickAction}>
			Deposit
		</Button.Secondary>
	);
};

const getMessageInfoForTradeformState = (
	isSpot: boolean,
	currentOrderType: UIOrderType,
	maxQuoteAvailable: number,
	connected: boolean,
	accountExists: boolean,
	tradeForm: DriftStore['tradeForm'],
	priceBoxValue: number,
	currentMarketPrice: number,
	hasCurrentPosition: boolean,
	isGeoblocked: boolean,
	minTradeSizeForMarket: number,
	marketSymbol: string,
	entryPrice: BigNum,
	exceedsLiquidity: boolean,
	marketType: MarketType,
	openAddAccountModal: () => void,
	isMaxSpotPositions: boolean,
	isMaxPerpPositions: boolean,
	oraclePrice: BigNum,
	l2State: L2OrderBook,
	isL2StateLoaded: boolean,
	currentPositionDirection?: 'long' | 'short',
	currentPositionSize?: BigNum,
	basePrecisionToUse?: BN,
	userHasLpShares?: boolean,
	accountName?: string,
	currentPositionIsZero?: boolean,
	market24hVol?: number,
	marketStatus?: MarketStatus,
	isPredictionMarket?: boolean,
	maxBaseAvailable?: number
): MessageInfo => {
	const isLiteMode = useIsLiteMode();

	const baseSize = BigNum.fromPrint(
		tradeForm.baseSizeStringValue,
		basePrecisionToUse || BASE_PRECISION_EXP
	);

	const roundedBaseSize = +UI_UTILS.roundToStepSizeIfLargeEnough(
		baseSize.toNum().toString(),
		tradeForm.stepSize
	);
	const roundedMaxBaseAvailable = +UI_UTILS.roundToStepSizeIfLargeEnough(
		maxBaseAvailable?.toString() || '0',
		tradeForm.stepSize
	);

	const tradeSizeIsTooSmall = !roundedBaseSize
		? !!tradeForm.quoteSizeStringValue
		: minTradeSizeForMarket !== undefined &&
		  roundedBaseSize < minTradeSizeForMarket;

	const tradeIsTooLarge =
		roundedBaseSize &&
		roundedMaxBaseAvailable &&
		roundedBaseSize > roundedMaxBaseAvailable;

	if (isGeoblocked) {
		return {
			shouldShowMessage: true,
			messageTitle: 'Limited Functionality',
			message: `You are using Drift from a restricted territory, therefore some functions will be unavailable to you.`,
			messageType: TradeFormMessageType.error,
			skipMessageInConfirmationModal: false,
		};
	}

	if (isPredictionMarket) {
		// Check that price is within bounds
		if (
			currentOrderType === 'limit' &&
			priceBoxValue &&
			(priceBoxValue <= 0 || priceBoxValue >= MAX_PREDICTION_PRICE_NUM)
		) {
			return {
				messageType: TradeFormMessageType.error,
				shouldShowMessage: true,
				messageTitle: 'Invalid Price',
				message: `Price must be between 0 and 1 for a Prediction Market`,
				skipMessageInConfirmationModal: false,
				disableTradeButton: true,
			};
		}
	}

	if (
		marketStatus &&
		ENUM_UTILS.match(marketStatus, MarketStatus.REDUCE_ONLY)
	) {
		const currentPositionSide =
			currentPositionDirection === 'long' ? 'buy' : 'sell';

		if (
			hasCurrentPosition &&
			currentPositionDirection &&
			currentPositionSide === tradeForm.side
		) {
			return {
				messageTitle: `Market is in Reduce Only mode`,
				shouldShowMessage: true,
				message: `You can only reduce your current position.`,
				messageType: TradeFormMessageType.warn,
				skipMessageInConfirmationModal: false,
				disableTradeButton: true,
			};
		}

		return {
			messageType: TradeFormMessageType.info,
			shouldShowMessage: true,
			messageTitle: 'This market is in Reduce Only mode',
			message: `Only orders that reduce existing positions will be allowed.`,
			skipMessageInConfirmationModal: false,
			disableTradeButton: false,
		};
	}

	if (
		isL2StateLoaded &&
		currentOrderType === 'market' &&
		!(l2State.bids.length >= 0 && l2State.asks.length >= 0) // at least one side has no liquidity
	) {
		const hasNoLiquidityAtAll =
			l2State.bids.length === 0 && l2State.asks.length === 0;
		const hasNoSellSideLiquidityButOnBuySideTradeForm =
			l2State.asks.length === 0 && tradeForm.side === 'buy';
		const hasNoBuySideLiquidityButOnSellSideTradeForm =
			l2State.bids.length === 0 && tradeForm.side === 'sell';

		const showNoLiquidityMessage =
			hasNoLiquidityAtAll ||
			hasNoSellSideLiquidityButOnBuySideTradeForm ||
			hasNoBuySideLiquidityButOnSellSideTradeForm;

		if (showNoLiquidityMessage) {
			return {
				messageType: TradeFormMessageType.error,
				shouldShowMessage: true,
				messageTitle: 'No Liquidity on Orderbook',
				message:
					'There is no liquidity on this market yet. Please use limit orders instead.',
				skipMessageInConfirmationModal: false,
				disableTradeButton: true,
			};
		}
	}

	// Trade Size Too Small Case
	if (tradeSizeIsTooSmall) {
		return {
			messageTitle: '',
			shouldShowMessage: true,
			message: `Order size must be greater than ${minTradeSizeForMarket} ${
				isPredictionMarket ? 'Shares' : marketSymbol
			}`,
			messageType: TradeFormMessageType.error,
			skipMessageInConfirmationModal: true,
			disableTradeButton: true,
		};
	}

	// Trade Size Too Large Case
	if (tradeIsTooLarge) {
		return {
			messageTitle: '',
			shouldShowMessage: true,
			message: `Order size must be less than or equal to ${UI_UTILS.roundToStepSizeIfLargeEnough(
				maxBaseAvailable.toString(),
				tradeForm.stepSize
			)} ${isPredictionMarket ? 'Shares' : marketSymbol}`,
			messageType: TradeFormMessageType.error,
			skipMessageInConfirmationModal: true,
			disableTradeButton: true,
		};
	}

	if (isMaxPerpPositions) {
		return {
			messageType: TradeFormMessageType.error,
			shouldShowMessage: true,
			messageTitle: '',
			message: `You have reached the maximum number of Perp positions (${MAX_NUMBER_OF_PERP_POSITIONS}) for a single user account. You will need to remove a position from another market before you can add liquidity to this pool.`,
			skipMessageInConfirmationModal: false,
			disableTradeButton: true,
		};
	}

	if (isMaxSpotPositions) {
		return {
			messageType: TradeFormMessageType.error,
			shouldShowMessage: true,
			messageTitle: MAX_SPOT_POSITIONS_ERROR_MESSAGE.title,
			message: MAX_SPOT_POSITIONS_ERROR_MESSAGE.message,
			skipMessageInConfirmationModal: false,
			disableTradeButton: true,
		};
	}

	if (exceedsLiquidity && currentOrderType === 'market') {
		return {
			messageType: TradeFormMessageType.warn,
			shouldShowMessage: true,
			messageTitle: 'Insufficient Liquidity',
			message: `The order size is too large for the available liquidity. You may want to open a limit order instead.`,
			skipMessageInConfirmationModal: false,
		};
	}

	// show message to suggest using swap if spot market is super low vol
	if (
		ENUM_UTILS.match(marketType, MarketType.SPOT) &&
		market24hVol != undefined &&
		market24hVol < 5000
	) {
		return {
			messageType: TradeFormMessageType.warn,
			shouldShowMessage: true,
			messageTitle: 'Low Volume Market',
			message: `This market has low 24h volume, you may find a better experience using Swap instead.`,
			skipMessageInConfirmationModal: false,
			disableTradeButton: false,
			messageLink: `https://app.drift.trade/swap/${marketSymbol.toUpperCase()}-USDC`,
			messageLinkDescription: 'Go to Swap',
		};
	}

	if (
		baseSize &&
		!baseSize.eqZero() &&
		(userHasLpShares || UI_UTILS.accountNameIsLp(accountName))
	) {
		return {
			messageTitle: ``,
			shouldShowMessage: true,
			message: (
				<div className="flex flex-col gap-1">
					<div>
						To easily track your P&amp;L, keep trading activity and LPing in
						separate subaccounts.
					</div>
					<Button.Secondary
						size="SMALL"
						onClick={openAddAccountModal}
						className="self-start"
					>
						Add subaccount
					</Button.Secondary>
				</div>
			),
			messageType: TradeFormMessageType.warn,
			skipMessageInConfirmationModal: false,
			disableTradeButton: false,
		};
	}

	if (SUPER_STAKE_ACCOUNT_NAMES.includes(accountName)) {
		return {
			messageTitle: ``,
			shouldShowMessage: true,
			message: (
				<div className="flex flex-col gap-1">
					<div>
						To easily track your P&amp;L, keep trading activity and Superstaking
						in separate subaccounts.
					</div>
					<Button.Secondary
						size="SMALL"
						onClick={openAddAccountModal}
						className="self-start"
					>
						Add subaccount
					</Button.Secondary>
				</div>
			),
			messageType: TradeFormMessageType.warn,
			skipMessageInConfirmationModal: false,
			disableTradeButton: false,
		};
	}

	if (connected && accountExists === false) {
		return {
			messageType: TradeFormMessageType.warn,
			shouldShowMessage: true,
			messageTitle: 'Deposit assets to start trading',
			message: <DepositButton />,
			skipMessageInConfirmationModal: false,
		};
	}

	const baseCase = {
		shouldShowMessage: false,
		messageTitle: '',
		message: '',
		messageType: TradeFormMessageType.info,
		skipMessageInConfirmationModal: true,
	};

	const isBuySide = tradeForm.side === 'buy';

	const secondaryPriceBoxValue = Number(tradeForm.secondaryPriceBoxStringValue);

	// In lite mode, bracket order warning messages are always disaplayed in the bracket order form so don't show them here
	if (tradeForm.bracketOrders && !isLiteMode) {
		const comparePrice =
			tradeForm.orderType === 'market'
				? entryPrice
				: BigNum.fromPrint(tradeForm.priceBoxStringValue, PRICE_PRECISION_EXP);

		if (tradeForm.bracketOrders.takeProfit) {
			if (
				tradeForm.bracketOrders.takeProfit.price.gt(comparePrice) &&
				tradeForm.side === 'sell'
			) {
				return {
					messageTitle: `Take Profit Bracket Order trigger oracle price is invalid`,
					shouldShowMessage: true,
					message: `Take Profit trigger oracle price must be below entry price`,
					messageType: TradeFormMessageType.error,
					skipMessageInConfirmationModal: false,
					disableTradeButton: true,
				};
			}

			if (
				tradeForm.bracketOrders.takeProfit.price.lt(comparePrice) &&
				tradeForm.side === 'buy'
			) {
				return {
					messageTitle: `Take Profit Bracket Order trigger oracle price is invalid`,
					shouldShowMessage: true,
					message: `Take Profit trigger oracle price must be above entry price`,
					messageType: TradeFormMessageType.error,
					skipMessageInConfirmationModal: false,
					disableTradeButton: true,
				};
			}
		}

		if (tradeForm.bracketOrders.stopLoss) {
			if (
				tradeForm.bracketOrders.stopLoss.price.gt(comparePrice) &&
				tradeForm.side === 'buy'
			) {
				return {
					messageTitle: `Stop Loss Bracket Order trigger oracle price is invalid`,
					shouldShowMessage: true,
					message: `Stop loss trigger oracle price must be below entry price`,
					messageType: TradeFormMessageType.error,
					skipMessageInConfirmationModal: false,
					disableTradeButton: true,
				};
			}

			if (
				tradeForm.bracketOrders.stopLoss.price.lt(comparePrice) &&
				tradeForm.side === 'sell'
			) {
				return {
					messageTitle: `Stop Loss Bracket Order trigger oracle price is invalid`,
					shouldShowMessage: true,
					message: `Stop Loss trigger oracle price must be above entry price`,
					messageType: TradeFormMessageType.error,
					skipMessageInConfirmationModal: false,
					disableTradeButton: true,
				};
			}
		}
	}

	// Check errors which apply to market orders only
	if (currentOrderType === 'market') {
		// None of the following error cases apply to market orders
		return baseCase;
	}

	// Case where reduce-only, and has a current position, but the current trade would make current position larger
	// We allow this case for users to preemptively set up their exits before getting into a position
	if (
		tradeForm.reduceOnly &&
		hasCurrentPosition &&
		currentPositionDirection &&
		(currentPositionDirection === 'long' ? 'buy' : 'sell') === tradeForm.side
	) {
		const oppositeOrderSide =
			currentPositionDirection === 'long'
				? ENUM_UTILS.match(marketType, MarketType.SPOT)
					? 'Sell'
					: 'Short'
				: ENUM_UTILS.match(marketType, MarketType.SPOT)
				? 'Buy'
				: 'Long';

		return {
			messageTitle: `Reduce Only Selected`,
			shouldShowMessage: true,
			message: `This order can only fill if you move into a "${oppositeOrderSide}" position. This feature is designed so that you can preemptively set up your exits before getting into a position. If this is not your intention then you may be putting through the wrong order.
			`,
			messageType: TradeFormMessageType.info,
			skipMessageInConfirmationModal: false,
			disableTradeButton: false,
		};
	}

	// Trade size too large case
	if (
		tradeForm.reduceOnly &&
		currentPositionSize &&
		!currentPositionSize.eq(ZERO) &&
		baseSize.gt(currentPositionSize.abs())
	) {
		return {
			messageTitle: `Trade size too large`,
			shouldShowMessage: true,
			message: `You can't open an order larger than your current position while reduce-only is enabled`,
			messageType: TradeFormMessageType.error,
			skipMessageInConfirmationModal: false,
			disableTradeButton: true,
		};
	}

	// No trade amount case
	if ((!baseSize || baseSize.eqZero()) && maxQuoteAvailable > 0) {
		return {
			shouldShowMessage: true,
			message: `Enter a trade amount`,
			messageTitle: '',
			messageType: TradeFormMessageType.warn,
			skipMessageInConfirmationModal: false,
			disableTradeButton: false,
		};
	}

	// Show no slippage tolerance warning if trigger market order
	if (
		currentOrderType === 'stopMarket' ||
		currentOrderType === 'takeProfitMarket'
	) {
		return {
			shouldShowMessage: true,
			message: `Global slippage tolerance does not apply to trigger market orders. Use a trigger limit order if you want to enforce a slippage price.`,
			messageTitle: '',
			messageType: TradeFormMessageType.warn,
			skipMessageInConfirmationModal: false,
			disableTradeButton: false,
		};
	}

	const orderViolationMessage = getMessageInfoForOrderViolation(
		currentOrderType,
		isSpot,
		priceBoxValue,
		currentMarketPrice,
		tradeForm.postOnly,
		secondaryPriceBoxValue,
		isBuySide ? 'long' : 'short',
		currentPositionDirection,
		oraclePrice?.toNum(),
		marketSymbol,
		isPredictionMarket && !isBuySide,
		l2State,
		currentPositionIsZero,
		baseSize.toNum(),
		tradeForm.stepSize
	);

	if (orderViolationMessage) return orderViolationMessage;

	return baseCase;
};

const marketSwitchPriceBoxUpdate = (
	defaultPrice: BigNum,
	currentOrderType: UIOrderType,
	direction: 'long' | 'short',
	currentPositionDirection: 'long' | 'short',
	slippageTolerance: number,
	priceBoxUpdate: (string) => void,
	secondaryPriceBoxUpdate: (string) => void,
	tickSize?: BigNum,
	savedLimitPrice?: BigNum
) => {
	if (!defaultPrice && !savedLimitPrice) return;

	const limitPriceToUse = savedLimitPrice ?? defaultPrice;

	const priceDefaultMod = 1 / 100; // 1% default offset for stop / take profit / limit
	if (currentOrderType == 'limit') {
		priceBoxUpdate(limitPriceToUse.toTradePrecision());
	} else if (currentOrderType == 'oracleLimit') {
		priceBoxUpdate(
			!tickSize
				? ''
				: direction === 'short'
				? tickSize.prettyPrint()
				: tickSize.neg().prettyPrint()
		);
	} else {
		const useHigherPrice =
			(currentPositionDirection === 'short' &&
				(currentOrderType == 'stopMarket' ||
					currentOrderType == 'stopLimit')) ||
			(currentPositionDirection === 'long' &&
				(currentOrderType == 'takeProfitMarket' ||
					currentOrderType == 'takeProfitLimit'));

		const isAdvancedLimit =
			currentOrderType == 'stopLimit' || currentOrderType == 'takeProfitLimit';

		let priceBoxVal = Number(defaultPrice.toTradePrecision());
		let secondaryBoxVal = Number(defaultPrice.toTradePrecision());

		if (useHigherPrice) {
			priceBoxVal = NumLib.formatNum.toTradePrecision(
				priceBoxVal * (1 + priceDefaultMod)
			);

			if (currentOrderType == 'stopLimit') {
				secondaryBoxVal = NumLib.formatNum.toTradePrecision(
					priceBoxVal * (1 + slippageTolerance)
				);
			}

			if (currentOrderType == 'takeProfitLimit') {
				secondaryBoxVal = NumLib.formatNum.toTradePrecision(
					priceBoxVal * (1 + slippageTolerance)
				);
			}
		} else {
			if (currentOrderType === 'stopLimit') {
				secondaryBoxVal = NumLib.formatNum.toTradePrecision(
					priceBoxVal * (1 - slippageTolerance)
				);
			}

			if (currentOrderType === 'takeProfitLimit') {
				secondaryBoxVal = NumLib.formatNum.toTradePrecision(
					priceBoxVal * (1 - slippageTolerance)
				);
			}
		}

		priceBoxUpdate(priceBoxVal ? priceBoxVal.toString() : '');

		if (isAdvancedLimit)
			secondaryPriceBoxUpdate(
				secondaryBoxVal ? secondaryBoxVal.toString() : ''
			);
	}
};

/**
 * A hook which does all of the logic to determine how the spot tradeform should behave based on its state
 * @returns
 */
export const usePerpTradeFormStateEngine = (): PerpTradeFormState => {
	// Generic App Hooks
	const setState = useDriftStore((s) => s.set);
	const isGeoblocked = useDriftStore((s) => s.isGeoblocked);
	const tradeForm = useDriftStore((s) => s.tradeForm);
	const currentOrderType = useDriftStore((s) => s.tradeForm.orderType);
	const driftClientIsReady = useDriftClientIsReady();
	const connected = useWalletIsConnected();
	const currentAccount = useAccountData();
	const accountExists = useAccountExists();
	const userAccountIsReady = useUserAccountIsReady();
	const [currentSettings] = useCurrentSettings();
	const {
		slippageTolerance: globalSlippageTolerance,
		allowInfSlippage: globalAllowInfSlippage,
	} = currentSettings;
	const currentPerpMarket = useCurrentPerpMarketAccount();
	const isMarketInReduceOnly = ENUM_UTILS.match(
		currentPerpMarket?.status,
		MarketStatus.REDUCE_ONLY
	);
	const isLiteMode = useIsLiteMode();
	const [lastUsedOrderType, setLastUsedOrderType] =
		useLocalStorageState<UIOrderType | null>('lastUsedPerpOrderType', null);
	const [debouncedShouldShowMessage, setDebouncedShouldShowMessage] =
		useState(false);

	const setPriceBoxValue = useSetTradeFormPriceBox();
	const setSecondaryPriceBoxValue = useSetTradeFormSecondaryPriceBox();
	const setBaseSizeStringValue = useSetTradeFormBaseSize();

	// React Refs
	const showMaxButtonMultiSwitch = useRef(new MultiSwitch('on'));
	const showMaxLeverageSliderMultiSwitch = useRef(new MultiSwitch('on'));
	const showReducePositionSliderMultiSwitch = useRef(new MultiSwitch('off'));

	// Selected Market
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const isSellPredictionMarketTradeForm = useDriftStore((s) =>
		s.checkIsSellPredictionMarket()
	);
	const checkIsSellPredictionMarket = useDriftStore(
		(s) => s.checkIsSellPredictionMarket
	);
	const selectedMarketId = selectedMarket?.marketId;
	const selectedMarketIndex = selectedMarketId?.marketIndex;

	// L2 state for est entry price to calculate max base size
	const l2State = useL2StateForMarket(
		selectedMarket?.marketIndex,
		selectedMarket?.marketType
	);

	const areOrderbooksLoaded = useMarketStateStore(
		(s) => s.latestUpdateSlot !== undefined
	);

	const dlobIsLoaded =
		!!l2State && !!(l2State?.asks?.length || l2State?.bids?.length);

	// Trade Form
	const quoteSize = Number(tradeForm.quoteSizeStringValue);
	const priceBoxValue = Number(tradeForm.priceBoxStringValue);
	const tradeFormSlippageTolerance = Math.max(
		0,
		tradeForm.slippageTolerance / 100
	);

	// Oracle and Market Prices
	const oraclePrice = useMemoizedOraclePrice(selectedMarket.marketId);
	const currentMarketPriceBigNum = useCurrentMarketMarkPrice();
	const currentMarketPrice = parseFloat(currentMarketPriceBigNum.print());

	const {
		entryPrice: entryPriceBn,
		showPriceEstimateOracleDivergenceWarning,
		exceedsLiquidity,
	} = useTradeformPriceImpact();

	const userWillExceedMaxPerpsPositions = useUserWillExceedMaxPerpsPositions(
		selectedMarket?.market.marketIndex
	);
	const userHasPositionInMarket =
		currentAccount?.openPerpPositions
			?.map((val) => val.marketIndex.toString())
			.includes(selectedMarketIndex?.toString()) ?? false;
	const userHasLpShares = currentAccount?.marginInfo?.quoteInLpOrders?.gt(ZERO);

	const savedPriceInfo = tradeForm.savedLimitPrice;
	const savedLimitPriceBigNum =
		savedPriceInfo && selectedMarket.marketId.equals(savedPriceInfo?.market)
			? BigNum.fromPrint(savedPriceInfo.price.toString(), PRICE_PRECISION_EXP)
			: undefined;

	const freeCollateral = NumLib.formatBn.fromQuote(
		currentAccount?.marginInfo?.freeCollateral ?? new BN(0)
	);

	const tickSize = currentPerpMarket?.amm?.orderTickSize;

	const currentPosition =
		userAccountIsReady && currentAccount
			? currentAccount.client
					?.getUserAccount()
					.perpPositions.find(
						(position) => position.marketIndex === selectedMarketIndex
					)
			: undefined;

	const currentPositionIsZero =
		!currentPosition || currentPosition.baseAssetAmount.eq(ZERO);
	const currentPositionDirection = currentPosition
		? currentPosition.baseAssetAmount.isNeg()
			? 'short'
			: 'long'
		: // for logical purposes position is "long" if position is zero
		  'long';
	const isCurrentPositionSellPredictionMarket = checkIsSellPredictionMarket({
		isPredictionMarket: selectedMarket.isPredictionMarket,
		isSellSide: currentPositionDirection === 'short',
	});

	const marketInSettlement = matchEnum(
		currentPerpMarket?.status,
		MarketStatus.SETTLEMENT
	);

	// Price Box Heading
	const priceBoxHeading = getPriceBoxHeading(currentOrderType);

	// Price Box Enabled
	const priceBoxEnabled = (() => {
		switch (currentOrderType) {
			case 'market':
				return false;
			case 'oracle':
				return false;
			default:
				return true;
		}
	})();

	// Show Slippage Tolerance
	const showSlippageTolerance = (() => {
		switch (currentOrderType) {
			case 'market':
				return true;
			default:
				return false;
		}
	})();

	const isInDepositAndTradeState = useDepositAndTradeShouldBeEnabled();

	// Show Secondary Price Box
	const showSecondaryPriceBox =
		getShouldShowSecondaryPriceBox(currentOrderType);

	// Show Reduce Only
	const showReduceOnly = useMemo(() => {
		if (isInDepositAndTradeState) return false;

		const selectedDirection = tradeForm.side == 'buy' ? 'long' : 'short';

		switch (currentOrderType) {
			case 'market':
				return (
					userHasPositionInMarket &&
					currentPositionDirection != selectedDirection
				);
			default:
				return true;
		}
	}, [
		isInDepositAndTradeState,
		tradeForm.side,
		currentOrderType,
		userHasPositionInMarket,
		currentPositionDirection,
	]);

	// Show Post Only
	const showPostOnly = useMemo(
		() =>
			!isInDepositAndTradeState &&
			(currentOrderType === 'limit' || currentOrderType === 'oracleLimit'),
		[currentOrderType, isInDepositAndTradeState]
	);

	/**
	 * Handle keeping reduce-only in sync
	 * 
	 * - On Market Orders, 
	 * 	- if ShowReduceOnly is true, then it should be switched to match the user's preference
	 * 	- if showReduceOnly is false, then it should be switched off
	 * - On Limit Orders,
	 * 	- switch should be set to match the user's preference
	 * 	- if switching reduce only on, then make sure we turn postOnly off
	 * - On STOP and TAKEPROFIT orders reduce only should default to true, without afftecting the user's preferences
	 * 		
		if (currentOrderType === 'limit' || currentOrderType === 'market') {
			setReduceOnly(reduceOnlyPreference);
			if (tradeForm.postOnly && reduceOnlyPreference) {
				setState((s) => {
					s.tradeForm.postOnly = false;
				});
			}
		}
	 */

	// React useEffects

	// When order type changes, default reduce-only to true for stop-loss or take-profit orders
	useEffect(() => {
		const defaultReduceOnlyOrderTypes: UIOrderType[] = [
			'stopLimit',
			'stopMarket',
			'takeProfitLimit',
			'takeProfitMarket',
		];

		if (defaultReduceOnlyOrderTypes.includes(currentOrderType)) {
			setReduceOnly(true);

			// Switch form to opposite side of current position if current position > 0
			if (
				currentPosition &&
				currentPositionDirection &&
				currentPosition.baseAssetAmount.abs().gt(ZERO)
			) {
				if (currentPositionDirection === 'long' && tradeForm.side === 'buy') {
					setState((s) => {
						s.tradeForm.side = 'sell';
					});
				} else if (
					currentPositionDirection === 'short' &&
					tradeForm.side === 'sell'
				) {
					setState((s) => {
						s.tradeForm.side = 'buy';
					});
				}
			}

			return;
		}

		if (!showReduceOnly) {
			setReduceOnly(false);
		}

		// dont think this is needed? was there a reason we flipped off post only if reduce only was on?
		// if (currentOrderType === 'limit') {
		// 	setReduceOnly(tradeForm.preferReduceOnly);
		// 	if (tradeForm.postOnly && tradeForm.preferReduceOnly) {
		// 		setState((s) => {
		// 			s.tradeForm.postOnly = false;
		// 		});
		// 	}
		// 	return;
		// }
	}, [
		currentOrderType,
		currentPositionDirection,
		currentPosition,
		selectedMarket,
		showReduceOnly,
	]);

	// Handle hiding max button based on reduce-only state
	useEffect(() => {
		let shouldShowMaxButtonValue = true;
		const tradeFormDirection = tradeForm.side === 'buy' ? 'long' : 'short';

		if (tradeForm.reduceOnly) {
			// If No Position, hide max button
			if (!currentPosition || currentPosition?.baseAssetAmount?.eq(ZERO)) {
				shouldShowMaxButtonValue = false;
			}

			// If same side as position and reduce-only, hide max button
			if (currentPosition && currentPositionDirection === tradeFormDirection) {
				shouldShowMaxButtonValue = false;
			}
		}

		const SWITCH_KEY = 'reduce_only_control';
		if (shouldShowMaxButtonValue) {
			showMaxButtonMultiSwitch.current.switchOn(SWITCH_KEY);
		} else {
			showMaxButtonMultiSwitch.current.switchOff(SWITCH_KEY);
		}

		if (tradeForm.reduceOnly && shouldShowMaxButtonValue) {
			showReducePositionSliderMultiSwitch.current.switchOn(SWITCH_KEY);
		} else {
			showReducePositionSliderMultiSwitch.current.switchOff(SWITCH_KEY);
		}

		if (tradeForm.reduceOnly) {
			showMaxLeverageSliderMultiSwitch.current.switchOff(SWITCH_KEY);
		} else {
			showMaxLeverageSliderMultiSwitch.current.switchOn(SWITCH_KEY);
		}
	}, [
		currentOrderType,
		currentPositionDirection,
		!!currentPosition,
		selectedMarket,
		tradeForm.side,
		tradeForm.reduceOnly,
	]);

	// Reset price box when changing markets
	useEffect(() => {
		if (!driftClientIsReady) return;
		if (currentOrderType === 'market') {
			setPriceBoxValue('');
			setSecondaryPriceBoxValue('');
		}
	}, [selectedMarket, driftClientIsReady, currentPositionDirection]);

	// Ref to track the first time the below "market switch effect" runs
	const marketSwitchEffectRanOnceRef = useRef(false);

	// Update main price box when changing markets for a non-Market Order
	useEffect(() => {
		if (!driftClientIsReady) return;

		if (!marketSwitchEffectRanOnceRef.current) {
			// 🚨 TODO :: MULTIPLE components are using this useTradeFormStateEngineHook. Because this effect hook will always run on the first render for any secondary components, this is being erroneously triggered HALF WAY THROUGH A TRADE (e.g. when the trade form confirmation window opens, the price box is being reset because that's what marketSwitchPriceBoxUpdate does). This is a band-aid fix that should be safe for a patch but we probably need to find a better solution.
			marketSwitchEffectRanOnceRef.current = true;
			return;
		}

		if (currentOrderType !== 'market') {
			if (
				(currentMarketPriceBigNum && !currentMarketPriceBigNum.eqZero()) ||
				savedLimitPriceBigNum
			) {
				const currentMarkPriceToUse = isSellPredictionMarketTradeForm
					? MAX_PREDICTION_PRICE_BIGNUM.sub(currentMarketPriceBigNum)
					: currentMarketPriceBigNum;

				marketSwitchPriceBoxUpdate(
					currentMarkPriceToUse,
					currentOrderType,
					tradeForm.side === 'buy' ? 'long' : 'short',
					currentPositionDirection,
					tradeFormSlippageTolerance,
					setPriceBoxValue,
					setSecondaryPriceBoxValue,
					tickSize ? BigNum.from(tickSize, PRICE_PRECISION_EXP) : undefined,
					savedLimitPriceBigNum
				);
			} else {
				setPriceBoxValue('');
				setSecondaryPriceBoxValue('');
			}
		}
	}, [
		selectedMarketId?.key,
		driftClientIsReady,
		currentOrderType,
		currentPositionDirection,
		tradeForm.side,
		savedLimitPriceBigNum?.toNum(),
		currentMarketPriceBigNum?.eqZero(),
		// tickSize?.toNumber(), 🚨 TODO Related to the TODO just above. The first time this hook runs currentPerpMarket is undefined and then has a value straight after. That causes this effect hook to tick twice quickly.
		isSellPredictionMarketTradeForm,
	]);

	useEffect(() => {
		if (currentPositionIsZero) {
			setState((s) => {
				s.tradeForm.isFlippingPosition = false;
			});
		} else {
			const isFlippingPosition =
				(currentPositionDirection === 'short' && tradeForm.side === 'buy') ||
				(currentPositionDirection === 'long' && tradeForm.side === 'sell');

			setState((s) => {
				s.tradeForm.isFlippingPosition = isFlippingPosition;
			});
		}
	}, [tradeForm.side, currentPositionDirection, currentPositionIsZero]);

	// Ref to track the first time the below "market switch effect" runs
	const showLeverageSliderEffectRanOnceRef = useRef(false);

	// Show Leverage Slider based on order type
	useEffect(() => {
		if (!showLeverageSliderEffectRanOnceRef.current) {
			// 🚨 TODO :: Related to the the 🚨 TODOs above
			showLeverageSliderEffectRanOnceRef.current = true;
			return;
		}

		const SWITCH_KEY = 'order_type_control';

		switch (currentOrderType) {
			case 'stopMarket':
			case 'takeProfitMarket':
			case 'stopLimit':
			case 'oracleLimit':
				setBaseSizeStringValue('');
				showMaxLeverageSliderMultiSwitch.current.switchOff(SWITCH_KEY);
				return;
			case 'takeProfitLimit':
				showMaxLeverageSliderMultiSwitch.current.switchOff(SWITCH_KEY);
				return;
			default:
				if (tradeForm.reduceOnly) {
					showMaxLeverageSliderMultiSwitch.current.switchOff(SWITCH_KEY);
					return;
				}
				showMaxLeverageSliderMultiSwitch.current.switchOn(SWITCH_KEY);
				return;
		}
	}, [currentOrderType, tradeForm.reduceOnly]);

	const [maxLeverageAvailable, setMaxLeverageAvailable] = useState(
		Env.defaultMaxMarketLeverage
	);

	// this needs to be split into current side and flipped side to handle the case for prediction markets where
	// the price is inverted for the short position, hence cost per share can be different for the same amount of quote for the different sides
	const [maxFundAvailableBreakdown, setMaxFundAvailableBreakdown] =
		useState<MaxFundsAvailableBreakdown>({
			newSide: {
				baseAmount: 0,
				quoteAmount: 0,
			},
			currentSide: {
				baseAmount: 0,
				quoteAmount: 0,
			},
		});

	const [maxQuoteAvailable, setMaxQuoteAvailable] = useState(0);

	const maxBaseAvailable =
		maxFundAvailableBreakdown.newSide.baseAmount +
		maxFundAvailableBreakdown.currentSide.baseAmount;

	const isPredictionMarket = ENUM_UTILS.match(
		currentPerpMarket?.contractType,
		ContractType.PREDICTION
	);

	// calculates the maxQuoteAvailable based on order type
	useEffect(() => {
		const oraclePriceBasedMaxBaseAvailable =
			maxFundAvailableBreakdown.newSide.quoteAmount +
			maxFundAvailableBreakdown.currentSide.quoteAmount;

		if (tradeForm.orderType === 'market') {
			// multiply maxBaseAvailable by Est. Entry Price when entering with max base
			setMaxQuoteAvailable(oraclePriceBasedMaxBaseAvailable);
		} else if (
			['limit', 'stopLimit', 'takeProfitLimit'].includes(tradeForm.orderType)
		) {
			// multiply maxBaseAvailable by Limit Price
			if (isPredictionMarket && tradeForm.isFlippingPosition) {
				const closeCurrentPositionMaxQuote =
					(MAX_PREDICTION_PRICE_NUM - +tradeForm.priceBoxStringValue) *
					maxFundAvailableBreakdown.currentSide.baseAmount;
				const openOppositePositionMaxQuote =
					+tradeForm.priceBoxStringValue *
					maxFundAvailableBreakdown.newSide.baseAmount;
				const totalQuoteAvailable =
					closeCurrentPositionMaxQuote + openOppositePositionMaxQuote;

				setMaxQuoteAvailable(totalQuoteAvailable);
			} else {
				const maxDynamicUiQuoteAvailable = +tradeForm.priceBoxStringValue
					? maxBaseAvailable * +tradeForm.priceBoxStringValue
					: 0;
				setMaxQuoteAvailable(maxDynamicUiQuoteAvailable);
			}
		} else {
			setMaxQuoteAvailable(oraclePriceBasedMaxBaseAvailable);
		}
	}, [
		tradeForm.orderType,
		tradeForm.isFlippingPosition,
		maxBaseAvailable,
		entryPriceBn.toNumber(),
		maxFundAvailableBreakdown,
		tradeForm.priceBoxStringValue,
		oraclePrice.toNum(),
		isPredictionMarket,
		currentPositionDirection,
	]);

	/* Keep tradeform slippage tolerance synced if updated from settings */
	useEffect(() => {
		setState((s) => {
			s.tradeForm.slippageTolerance =
				globalSlippageTolerance === 'inf'
					? undefined
					: Number(globalSlippageTolerance);
			s.tradeForm.allowInfSlippage = globalAllowInfSlippage;
		});
	}, [globalSlippageTolerance, globalAllowInfSlippage]);

	const setDefaultMaxFundAvailableBreakdown = () => {
		setMaxFundAvailableBreakdown({
			newSide: {
				quoteAmount: 0,
				baseAmount: 0,
			},
			currentSide: {
				quoteAmount: 0,
				baseAmount: 0,
			},
		});
	};

	// Maintain max allowed quote and base amount state
	useEffect(() => {
		if (
			!userAccountIsReady ||
			!driftClientIsReady ||
			!currentAccount ||
			!currentAccount?.client?.isSubscribed
		) {
			setDefaultMaxFundAvailableBreakdown();
			return;
		}

		const orderType = tradeForm.orderType;
		const isUserPriceDependentOrderType =
			orderType === 'limit' ||
			orderType === 'stopLimit' ||
			orderType === 'stopMarket' ||
			orderType === 'takeProfitLimit' ||
			orderType === 'takeProfitMarket';

		// if the oracle price is 0, the program allows the user to input infinite base amount, regardless of the order type (even for limit orders)
		// we still want to cap the user's input amount on the UI if possible, in this case limit orders allows for that
		const shouldUseTradeFormPrice =
			oraclePrice.eqZero() && isUserPriceDependentOrderType;

		const tradeFormPrice = BigNum.fromPrint(
			tradeForm.priceBoxStringValue,
			PRICE_PRECISION_EXP
		);

		const priceToUse = shouldUseTradeFormPrice ? tradeFormPrice : oraclePrice;
		const priceToUseIsInverted =
			isSellPredictionMarketTradeForm && shouldUseTradeFormPrice; // If we're using the user-entered price for a sell prediction market they have already entered the inverse price

		const nonInversePriceToUse = priceToUseIsInverted
			? MAX_PREDICTION_PRICE_BIGNUM.sub(priceToUse)
			: priceToUse;

		const inversePriceToUse = priceToUseIsInverted
			? priceToUse
			: MAX_PREDICTION_PRICE_BIGNUM.sub(priceToUse);

		// both oracle price and user input price are 0
		if (priceToUse.eqZero()) {
			setDefaultMaxFundAvailableBreakdown();
			return;
		}

		if (isMarketInReduceOnly) {
			// Handle reduce only case and return early because it's easier to reason about

			if (!currentPosition) {
				// prevent opening a position when market is in reduce only mode
				setDefaultMaxFundAvailableBreakdown();
				return;
			}

			if (!tradeForm.isFlippingPosition) {
				// prevent increasing position size when market is in reduce only mode
				setDefaultMaxFundAvailableBreakdown();
				return;
			}

			// The max size available to reduce is the same as the current position size
			const currentPositionBaseSizeBigNum = BigNum.from(
				currentPosition.baseAssetAmount,
				BASE_PRECISION_EXP
			).abs();

			// If it's prediction market then the position closing price should be the inverse price if the position is short. Otherwise it should be the non-inverse price
			const priceForClosingCurrentPosition = isPredictionMarket
				? currentPositionDirection === 'short'
					? inversePriceToUse
					: nonInversePriceToUse
				: nonInversePriceToUse;

			setMaxFundAvailableBreakdown({
				newSide: {
					quoteAmount: 0,
					baseAmount: 0,
				},
				currentSide: {
					quoteAmount: currentPositionBaseSizeBigNum
						.mul(priceForClosingCurrentPosition)
						.div(BASE_PRECISION)
						.toNum(),
					baseAmount: currentPositionBaseSizeBigNum.toNum(),
				},
			});
			return;
		}

		const maxTradeSizeUsdc = currentAccount.client.getMaxTradeSizeUSDCForPerp(
			selectedMarketIndex,
			tradeForm.side === 'buy'
				? PositionDirection.LONG
				: PositionDirection.SHORT
		);

		if (!isPredictionMarket) {
			// If it's not a prediction market we can do the regular maxFundAvailable calculation without worrying about inverting any prices
			setMaxFundAvailableBreakdown(
				handleTotalMaxTradeSizeCalculation(
					nonInversePriceToUse,
					nonInversePriceToUse,
					maxTradeSizeUsdc
				)
			);
			return;
		}

		// Now we need to handle the non-reduce only case for prediction markets
		const tradeFormSideIsShort = tradeForm.side === 'sell';

		// If the trade form side is short then we know the final price is the inverse price
		const finalPriceToUse = tradeFormSideIsShort
			? inversePriceToUse
			: nonInversePriceToUse;

		// If the trade form side is short then the current position price is the non-inverse price - note that currentPriceToUse is only relevant for flipping positions
		const currentPriceToUse = tradeFormSideIsShort
			? nonInversePriceToUse
			: inversePriceToUse;

		setMaxFundAvailableBreakdown(
			handleTotalMaxTradeSizeCalculation(
				finalPriceToUse,
				currentPriceToUse,
				maxTradeSizeUsdc
			)
		);

		return;
	}, [
		freeCollateral,
		maxLeverageAvailable,
		currentPosition,
		selectedMarketIndex,
		tradeForm.side,
		tradeForm.orderType,
		currentMarketPrice,
		userAccountIsReady,
		driftClientIsReady,
		connected,
		oraclePrice?.toString(),
		dlobIsLoaded,
		l2State,
		tradeForm.priceBoxStringValue,
		isSellPredictionMarketTradeForm,
		currentPositionDirection,
		isPredictionMarket,
		currentPositionIsZero,
		tradeForm.isFlippingPosition,
		currentAccount,
		currentAccount?.client?.isSubscribed,
		isMarketInReduceOnly,
		isCurrentPositionSellPredictionMarket,
	]);

	// Maintain max allowed leverage state
	useEffect(() => {
		if (!userAccountIsReady || !driftClientIsReady || !connected) {
			setMaxLeverageAvailable(Env.defaultMaxMarketLeverage);
			return;
		}

		if (freeCollateral === 0) return;

		try {
			const newMaxLeverage = BigNum.from(
				currentAccount.client.getMaxLeverageForPerp(selectedMarketIndex),
				FOUR
			).toNum();

			setMaxLeverageAvailable(newMaxLeverage);
		} catch (e) {
			// little race condition here after wallet disconnect so wrap in try/catch
		}

		return;
	}, [
		selectedMarketIndex,
		currentAccount,
		maxQuoteAvailable,
		freeCollateral,
		userAccountIsReady,
		driftClientIsReady,
		connected,
	]);

	// used to prevent infinite loop between updating tradeform store and local storage state
	const [
		hasSetInitialTradeFormOrderTypeState,
		setHasSetInitialTradeFormOrderTypeState,
	] = useState(false);

	// initialize the order type in the trade form store, updates the order type when switching between lite & pro mode
	useEffect(() => {
		setState((s) => {
			s.tradeForm.reduceOnly = false;
			if (isLiteMode) {
				if (lastUsedOrderType === 'market' || lastUsedOrderType === 'limit') {
					s.tradeForm.orderType = lastUsedOrderType;
				} else {
					s.tradeForm.orderType = 'market';
				}
			} else {
				const lastUsedOrderTypeIsValid = !!UI_ORDER_TYPES[lastUsedOrderType];
				s.tradeForm.orderType = lastUsedOrderTypeIsValid
					? lastUsedOrderType
					: 'limit';
			}
		});
		setHasSetInitialTradeFormOrderTypeState(true);
	}, [isLiteMode, lastUsedOrderType]);

	// Save last used order type to local storage
	useEffect(() => {
		// we don't want to set the local storage state until after the initial trade form state has been updated
		if (!hasSetInitialTradeFormOrderTypeState) {
			return;
		}

		setLastUsedOrderType(currentOrderType);
	}, [currentOrderType, hasSetInitialTradeFormOrderTypeState]);

	const minTradeSizeForMarket = currentPerpMarket
		? BigNum.from(
				currentPerpMarket.amm.minOrderSize,
				BASE_PRECISION_EXP
		  ).toNum()
		: undefined;

	const openAddAccountModal = () => {
		setState((s) => {
			s.modals.showNewSubaccountModal = true;
		});
	};

	const setReduceOnly = (newVal: boolean) => {
		setState((s) => {
			s.tradeForm.reduceOnly = newVal;
		});
	};

	const {
		messageType,
		shouldShowMessage,
		message,
		skipMessageInConfirmationModal,
		messageLink,
		messageLinkDescription,
		messageTitle,
		disableTradeButton,
		messageId,
	} = getMessageInfoForTradeformState(
		false,
		currentOrderType,
		maxQuoteAvailable,
		connected,
		accountExists,
		tradeForm,
		priceBoxValue,
		currentMarketPrice,
		userHasPositionInMarket,
		isGeoblocked,
		minTradeSizeForMarket,
		selectedMarket.baseAssetSymbol(),
		BigNum.from(entryPriceBn, PRICE_PRECISION_EXP),
		exceedsLiquidity,
		MarketType.PERP,
		openAddAccountModal,
		false,
		userWillExceedMaxPerpsPositions,
		oraclePrice ? oraclePrice : undefined,
		l2State,
		areOrderbooksLoaded,
		currentPositionDirection,
		undefined,
		undefined,
		userHasLpShares,
		currentAccount?.name,
		currentPositionIsZero,
		undefined,
		currentPerpMarket?.status,
		isPredictionMarket,
		maxBaseAvailable
	);

	// causing infinite re-render atm.. need to refactor
	// useEffect(() => {
	// 	setState((s) => {
	// 		s.tradeForm.message = message;
	// 		s.tradeForm.messageType = messageType;
	// 		s.tradeForm.skipMessageInConfirmationModal =
	// 			skipMessageInConfirmationModal;
	// 	});
	// }, [skipMessageInConfirmationModal, message, messageType]);

	// we only want to debounce the message if it is showing
	useDebounce(
		() => {
			setDebouncedShouldShowMessage(shouldShowMessage);
		},
		500,
		[shouldShowMessage]
	);
	useEffect(() => {
		if (!shouldShowMessage) {
			setDebouncedShouldShowMessage(false);
		}
	}, [shouldShowMessage]);

	const tradeButtonIsDisabled =
		UI_UTILS.valueIsBelowStepSize(
			tradeForm.baseSizeStringValue,
			tradeForm.stepSize
		) ||
		marketInSettlement ||
		disableTradeButton ||
		!tradeForm.baseSizeStringValue ||
		(!connected && !isInDepositAndTradeState) ||
		!quoteSize;

	return {
		orderType: currentOrderType,
		priceBoxHeading,
		priceBoxEnabled,
		showSlippageTolerance,
		shouldShowMessage: debouncedShouldShowMessage,
		messageType,
		message,
		skipMessageInConfirmationModal,
		showSecondaryPriceBox,
		showReduceOnly,
		showPostOnly,
		tradeButtonIsDisabled,
		currentPosition,
		currentMarketPrice,
		messageLink,
		messageLinkDescription,
		messageTitle,
		maxQuoteAvailable,
		maxBaseAvailable,
		maxFundAvailableBreakdown,
		maxLeverageAvailable,
		messageId,
		marketInSettlement,
		showMaxButton: showMaxButtonMultiSwitch.current.isOn,
		showLeverageSlider: showMaxLeverageSliderMultiSwitch.current.isOn,
		showReducePositionSlider: showReducePositionSliderMultiSwitch.current.isOn,
		showPriceEstimateOracleDivergenceWarning:
			!isPredictionMarket && // Disable this warning for prediction markets
			showPriceEstimateOracleDivergenceWarning &&
			currentOrderType === 'market',
		isInDepositAndTradeState,
	};
};

export const getPerpLiqPrice = (
	estEntryPrice: BN,
	orderType: UIOrderType,
	marketAccount: PerpMarketAccount,
	baseSizeStringValue: string,
	priceBoxStringValue: string,
	isLong: boolean,
	userClient: User,
	offsetCollateral?: BN,
	precision = 2
) => {
	if (!marketAccount) return 0;

	let baseAssetBN = BigNum.fromPrint(
		baseSizeStringValue,
		AMM_RESERVE_PRECISION_EXP
	).val;

	if (baseAssetBN.abs().gte(marketAccount?.amm.baseAssetReserve)) {
		return 0;
	}

	if (!isLong) {
		baseAssetBN = baseAssetBN.mul(new BN(-1));
	}

	let priceToUse;
	if (orderType === 'limit' && priceBoxStringValue) {
		const limitPriceBN = BigNum.fromPrint(
			priceBoxStringValue,
			PRICE_PRECISION_EXP
		).val;
		priceToUse = isLong
			? BN.min(estEntryPrice, limitPriceBN)
			: BN.max(estEntryPrice, limitPriceBN);
	} else {
		priceToUse = estEntryPrice;
	}

	const liqPriceBn = userClient
		? userClient.liquidationPrice(
				marketAccount.marketIndex,
				baseAssetBN,
				priceToUse,
				undefined,
				undefined,
				offsetCollateral
		  )
		: new BN(0);

	const liqPriceNum = NumLib.formatBn.toRawNum(liqPriceBn, PRICE_PRECISION);
	if (liqPriceNum <= 0) {
		return 0;
	}

	const returnVal = Math.abs(
		Math.round(liqPriceNum * 10 ** precision) / 10 ** precision
	);

	return returnVal;
};

/**
 * A hook which does all of the logic to determine how the spot tradeform should behave based on its state
 * @returns
 */
export const useSpotTradeFormStateEngine = (): TradeFormState => {
	// Generic App Hooks
	const currentAccount = useAccountData();
	const setState = useDriftStore((s) => s.set);
	const isGeoblocked = useDriftStore((s) => s.isGeoblocked);
	const tradeForm = useDriftStore((s) => s.tradeForm);
	const currentOrderType = useDriftStore((s) => s.tradeForm.orderType);
	const connected = useWalletIsConnected();
	const userAccountIsReady = useUserAccountIsReady();
	const driftClientIsReady = useDriftClientIsReady();
	const accountExists = useAccountExists();
	const marketInfoStore = useMarketsInfoStore();
	const marketsData = use24hMarketDataFromStore();

	const setPriceBoxValue = useSetTradeFormPriceBox();
	const setSecondaryPriceBoxValue = useSetTradeFormSecondaryPriceBox();
	const setQuoteFormValue = useSetTradeFormQuoteSize();
	const setBaseSizeStringValue = useSetTradeFormBaseSize();

	// Hook states
	const [maxQuoteAvailable, setMaxQuoteAmountAvailable] = useState(0);
	const [maxBaseAvailable, setMaxBaseAmountAvailable] = useState(0);
	const [maxLeverageAvailable, setMaxLeverageAvailable] = useState(
		Env.defaultMaxMarketLeverage
	);

	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const selectedMarketIndex = selectedMarket.market.marketIndex;
	const spotMarketAccount = marketInfoStore?.getMarketInfoByIndexAndType(
		selectedMarketIndex,
		MarketType.SPOT
	)?.account as SpotMarketAccount;
	const tickSize = spotMarketAccount?.orderTickSize;
	const l2State = useL2StateForMarket(
		selectedMarket.marketIndex,
		selectedMarket.marketType
	);
	const areOrderbooksLoaded = useMarketStateStore(
		(s) => s.latestUpdateSlot !== undefined
	);

	const userWillExceedMaxSpotPositions =
		useExceedsMaxSpotBalance(selectedMarketIndex);
	const marketPriceBigNum = useMarkPrice();
	const marketPrice = parseFloat(marketPriceBigNum.toTradePrecision());

	const savedPriceInfo = tradeForm.savedLimitPrice;
	const savedLimitPrice =
		savedPriceInfo && selectedMarket.marketId.equals(savedPriceInfo?.market)
			? BigNum.fromPrint(savedPriceInfo.price.toString(), PRICE_PRECISION_EXP)
			: undefined;

	const basePrecisionToUse = (selectedMarket.market as SpotMarketConfig)
		.precisionExp;

	const currentPosition = useAccountTargetSpotBalance(
		selectedMarket.market as SpotMarketConfig,
		currentAccount?.userKey ?? ''
	);

	const oraclePrice = useMemoizedOraclePrice(selectedMarket.marketId);

	const [currentSettings] = useCurrentSettings();
	const {
		slippageTolerance: globalSlippageTolerance,
		allowInfSlippage: globalAllowInfSlippage,
	} = currentSettings;

	const userHasMarginEnabled = currentAccount?.marginEnabled ?? false;

	const {
		entryPrice: entryPriceBn,
		showPriceEstimateOracleDivergenceWarning,
		exceedsLiquidity,
	} = useTradeformPriceImpact();

	const currentMarketPriceBigNum = useCurrentMarketMarkPrice();
	const currentMarketPrice = parseFloat(currentMarketPriceBigNum.print());

	const userHasPositionInMarket = !currentPosition.netBaseBalance.eqZero();
	const userHasLpShares = currentAccount?.marginInfo?.quoteInLpOrders?.gt(ZERO);

	const slippageTolerance = Math.max(
		0,
		useDriftStore((s) => s.tradeForm.slippageTolerance) / 100
	);

	const quoteSize = Number(tradeForm.quoteSizeStringValue);
	const priceBoxValue = Number(tradeForm.priceBoxStringValue);

	const freeCollateral = NumLib.formatBn.fromQuote(
		currentAccount?.marginInfo?.freeCollateral ?? new BN(0)
	);

	const currentPositionDirection = currentPosition
		? currentPosition.netBaseBalance.isNeg()
			? 'short'
			: 'long'
		: // for logical purposes position is "long" if position is zero
		  'long';

	const minTradeSizeForMarket =
		BigNum.from(
			spotMarketAccount?.minOrderSize,
			spotMarketAccount?.decimals
		)?.toNum() ?? marketStepSizes[selectedMarket.baseAssetSymbol()];

	// Price Box Heading
	const priceBoxHeading = getPriceBoxHeading(currentOrderType);

	// Price Box Enabled
	const priceBoxEnabled = (() => {
		switch (currentOrderType) {
			case 'market':
				return false;
			case 'oracle':
				return false;
			default:
				return true;
		}
	})();

	// Show Slippage Tolerance
	const showSlippageTolerance = (() => {
		switch (currentOrderType) {
			case 'market':
				return true;
			default:
				return false;
		}
	})();

	// Show Secondary Price Box
	const showSecondaryPriceBox =
		getShouldShowSecondaryPriceBox(currentOrderType);

	// Show Reduce Only
	const showReduceOnly = (() => {
		const selectedDirection = tradeForm.side == 'buy' ? 'long' : 'short';
		switch (currentOrderType) {
			case 'market':
				return (
					userHasPositionInMarket &&
					currentPositionDirection != selectedDirection
				);
			default:
				return true;
		}
	})();

	// Show Post Only
	const showPostOnly = useMemo(
		() => currentOrderType === 'limit' || currentOrderType === 'oracleLimit',
		[currentOrderType]
	);

	// Show Leverage Slider
	const showLeverageSlider = useMemo(() => {
		switch (currentOrderType) {
			case 'stopMarket':
			case 'takeProfitMarket':
			case 'stopLimit':
			case 'takeProfitLimit':
				return false;
			case 'oracleLimit':
				return false;
			default:
				if (tradeForm.reduceOnly) return false;
				return true;
		}
	}, [currentOrderType, tradeForm.reduceOnly]);

	const showMaxButton = (() => {
		let shouldShowMaxButtonValue = true;
		const tradeFormDirection = tradeForm.side === 'buy' ? 'long' : 'short';

		if (tradeForm.reduceOnly) {
			// If no position or if same side as position, hide max button
			if (
				!userHasPositionInMarket ||
				currentPositionDirection === tradeFormDirection
			) {
				shouldShowMaxButtonValue = false;
			}
		}

		return shouldShowMaxButtonValue;
	})();

	// Show Reduce Position Slider
	const showReducePositionSlider = (() => {
		if (!tradeForm.reduceOnly) return false;

		const tradeFormDirection = tradeForm.side === 'buy' ? 'long' : 'short';

		switch (currentOrderType) {
			case 'market':
				return userHasPositionInMarket;
			default:
				return (
					userHasPositionInMarket &&
					tradeFormDirection !== currentPositionDirection
				);
		}
	})();

	// When order type changes, default reduce-only to true for limit orders
	useEffect(() => {
		if (!userHasPositionInMarket) {
			// no position, so don't put set to reduce only regardless
			setReduceOnly(false);
			setState((s) => {
				s.tradeForm.immediateOrCancel = false;
			});
			return;
		}

		if (
			['market', 'takeProfitMarket', 'stopMarket'].includes(currentOrderType)
		) {
			setState((s) => {
				s.tradeForm.postOnly = false;
			});
		}

		if (currentOrderType !== 'limit' && currentOrderType !== 'market') {
			setReduceOnly(true);

			// Switch trade form to avoid bad reduce-only case
			if (currentPositionDirection) {
				if (currentPositionDirection === 'long' && tradeForm.side === 'buy') {
					setState((s) => {
						s.tradeForm.side = 'sell';
					});
				} else if (
					currentPositionDirection === 'short' &&
					tradeForm.side === 'sell'
				) {
					setState((s) => {
						s.tradeForm.side = 'buy';
					});
				}
			}
		} else {
			setState((s) => {
				s.tradeForm.immediateOrCancel = false;
			});
		}
	}, [currentOrderType, currentPositionDirection]);

	// Update main price box when changing markets for a non-Market Order
	useEffect(() => {
		if (!driftClientIsReady) return;

		if (currentOrderType === 'oracleLimit') {
			setBaseSizeStringValue('');
		}

		if (currentOrderType !== 'market') {
			if (
				(currentMarketPriceBigNum && !currentMarketPriceBigNum.eqZero()) ||
				savedLimitPrice
			) {
				marketSwitchPriceBoxUpdate(
					currentMarketPriceBigNum,
					currentOrderType,
					tradeForm.side === 'buy' ? 'long' : 'short',
					currentPositionDirection,
					slippageTolerance,
					setPriceBoxValue,
					setSecondaryPriceBoxValue,
					tickSize ? BigNum.from(tickSize, PRICE_PRECISION_EXP) : undefined,
					savedLimitPrice
				);
			} else {
				setPriceBoxValue('');
				setSecondaryPriceBoxValue('');
			}
		}
	}, [
		selectedMarketIndex,
		driftClientIsReady,
		currentOrderType,
		currentPositionDirection,
		tradeForm.side,
		savedLimitPrice?.toNum(),
		currentMarketPriceBigNum?.eqZero(),
	]);

	// Maintain max allowed quote and base state
	useEffect(() => {
		if (!userAccountIsReady || !driftClientIsReady || !currentAccount) {
			setMaxQuoteAmountAvailable(0);
			setMaxBaseAmountAvailable(0);
			return;
		}

		const maxQuoteAllowable = currentAccount.client.getMaxTradeSizeUSDCForSpot(
			selectedMarketIndex,
			tradeForm.side === 'buy'
				? PositionDirection.LONG
				: PositionDirection.SHORT
		);

		setMaxQuoteAmountAvailable(
			BigNum.from(maxQuoteAllowable, QUOTE_PRECISION_EXP).toNum()
		);

		if (oraclePrice && oraclePrice.gt(ZERO)) {
			setMaxBaseAmountAvailable(
				BigNum.from(maxQuoteAllowable, QUOTE_PRECISION_EXP)
					.scale(100_000, oraclePrice.toNum() * 100_000)
					.toNum()
			);
		}

		return;
	}, [
		freeCollateral,
		maxLeverageAvailable,
		selectedMarketIndex,
		tradeForm.side,
		marketPrice,
		userAccountIsReady,
		driftClientIsReady,
		connected,
		oraclePrice?.toString(),
	]);

	// Maintain max allowed leverage state
	useEffect(() => {
		if (!userAccountIsReady || !driftClientIsReady || !connected) {
			setMaxLeverageAvailable(Env.defaultMaxMarketLeverage);
			return;
		}

		if (freeCollateral === 0) return;

		try {
			const newMaxLeverage = BigNum.from(
				currentAccount.client.getMaxLeverageForSpot(
					selectedMarketIndex,
					tradeForm.side === 'buy'
						? PositionDirection.LONG
						: PositionDirection.SHORT
				),
				FOUR
			).toNum();

			setMaxLeverageAvailable(newMaxLeverage);
		} catch (e) {
			// little race condition here after wallet disconnect so wrap in try/catch
		}

		return;
	}, [
		selectedMarketIndex,
		currentAccount?.userKey,
		tradeForm.side,
		maxQuoteAvailable,
		freeCollateral,
		userAccountIsReady,
		driftClientIsReady,
		connected,
	]);

	/* Keep tradeform slippage tolerance synced if updated from settings */
	useEffect(() => {
		setState((s) => {
			s.tradeForm.slippageTolerance =
				globalSlippageTolerance === 'inf'
					? undefined
					: Number(globalSlippageTolerance);
			s.tradeForm.allowInfSlippage = globalAllowInfSlippage;
		});
	}, [globalSlippageTolerance, globalAllowInfSlippage]);

	// can't be reduce only if we're not showing it
	useEffect(() => {
		if (!showReduceOnly) {
			setReduceOnly(false);
		}
	}, [showReduceOnly, tradeForm.reduceOnly]);

	const usingSpotReduceOnlyOverride = useRef(false);

	useEffect(() => {
		if (!userHasMarginEnabled && tradeForm.side === 'sell') {
			usingSpotReduceOnlyOverride.current = true;
			setReduceOnly(true);
		} else if (usingSpotReduceOnlyOverride.current) {
			usingSpotReduceOnlyOverride.current = false;
			setReduceOnly(false);
		}
	}, [userHasMarginEnabled, tradeForm.side]);

	const isInDepositToTradeFlow = useDriftStore(
		(s) => s.tradeForm.isInDepositToTradeFlow
	);
	const roundDownForMax = !isInDepositToTradeFlow;

	// Handles rounding down trade form input size to the max allowed for the user
	// TODO :: Should this be done in the tradeform_INPUT_StateEngine ???
	useEffect(() => {
		if (!roundDownForMax) return;

		if (
			tradeForm.orderType == 'market' &&
			Number(tradeForm.quoteSizeStringValue) > maxQuoteAvailable
		) {
			setState((s) => {
				s.tradeForm.leadSide = 'quote';
			});
			setQuoteFormValue(String(maxQuoteAvailable));
		}
	}, [
		tradeForm.side,
		maxQuoteAvailable,
		tradeForm.quoteSizeStringValue,
		tradeForm.baseSizeStringValue,
		tradeForm.orderType,
	]);

	const openAddAccountModal = () => {
		setState((s) => {
			s.modals.showNewSubaccountModal = true;
		});
	};

	const setReduceOnly = (newVal: boolean) => {
		setState((s) => {
			s.tradeForm.reduceOnly = newVal;
		});
	};

	const market24hVol = marketsData?.find(
		(mkt) =>
			mkt.marketIndex === selectedMarketIndex &&
			ENUM_UTILS.match(mkt.marketType, selectedMarket.marketType)
	)?.quoteVolume;

	const {
		messageType,
		shouldShowMessage,
		message,
		skipMessageInConfirmationModal,
		messageLink,
		messageLinkDescription,
		messageTitle,
		disableTradeButton,
		messageId,
	} = getMessageInfoForTradeformState(
		true,
		currentOrderType,
		maxQuoteAvailable,
		connected,
		accountExists,
		tradeForm,
		priceBoxValue,
		currentMarketPrice,
		userHasPositionInMarket,
		isGeoblocked,
		minTradeSizeForMarket,
		selectedMarket.baseAssetSymbol(),
		BigNum.from(entryPriceBn, PRICE_PRECISION_EXP),
		exceedsLiquidity,
		MarketType.SPOT,
		openAddAccountModal,
		userWillExceedMaxSpotPositions,
		false,
		oraclePrice ? oraclePrice : undefined,
		l2State,
		areOrderbooksLoaded,
		currentPositionDirection,
		currentPosition.netBaseBalance.abs(),
		basePrecisionToUse,
		userHasLpShares,
		currentAccount?.name,
		undefined,
		market24hVol,
		maxBaseAvailable
	);

	const tradeButtonIsDisabled =
		UI_UTILS.valueIsBelowStepSize(
			tradeForm.baseSizeStringValue,
			tradeForm.stepSize
		) ||
		disableTradeButton ||
		!tradeForm.baseSizeStringValue ||
		!connected ||
		!quoteSize;

	// causing infinite re-render atm.. need to refactor
	// useEffect(() => {
	// 	setState((s) => {
	// 		s.tradeForm.message = message;
	// 		s.tradeForm.messageType = messageType;
	// 		s.tradeForm.skipMessageInConfirmationModal =
	// 			skipMessageInConfirmationModal;
	// 	});
	// }, [skipMessageInConfirmationModal, message, messageType]);

	return {
		orderType: currentOrderType,
		priceBoxHeading,
		priceBoxEnabled,
		showSlippageTolerance,
		shouldShowMessage,
		messageType,
		message,
		skipMessageInConfirmationModal,
		showSecondaryPriceBox,
		showReduceOnly,
		showPostOnly,
		showLeverageSlider,
		tradeButtonIsDisabled,
		currentPosition: undefined,
		maxQuoteAvailable,
		maxBaseAvailable,
		currentMarketPrice,
		showReducePositionSlider,
		messageLink,
		messageLinkDescription,
		messageTitle,
		maxLeverageAvailable,
		messageId,
		showMaxButton,
		showPriceEstimateOracleDivergenceWarning:
			showPriceEstimateOracleDivergenceWarning && currentOrderType === 'market',
	};
};
